import React from 'react';
import PropTypes from "prop-types";
import { Modal } from 'components/notifications';
import { Intent } from '@blueprintjs/core';
import { Key } from 'components/key';

export function KeyModal(props) {
    const { showKeyModal, closeKeyModal, isInitialising, ownershipForKeyModal, assetStatusesForKeyModal, vehicleActivitiesForKeyModal } = props;

    return (
        <Modal
            isOpen={showKeyModal}
            clickOutsideClose
            onClose={closeKeyModal}
            title="Key"
            icon="warning-sign"
            intent={Intent.DANGER}
            confirmButtonText="Dismiss"
            onConfirm={closeKeyModal}
        >
            <div>
                <div className="row spacer-bottom">
                    <div className="col">
                        <h5 className="key">Ownership</h5>
                        <Key rounded listVertical loading={isInitialising} items={ownershipForKeyModal} />
                    </div>
                    <div className="col spacer-left">
                        <h5 className="key">Asset Status</h5>
                        <Key rounded listVertical loading={isInitialising} items={assetStatusesForKeyModal} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h5 className="key">Vehicle Activities</h5>
                        <Key listVertical loading={isInitialising} items={vehicleActivitiesForKeyModal} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

KeyModal.defaultProps = {
    showKeyModal: false,
    closeKeyModal: () => { },
    isInitialising: false,
    ownershipForKeyModal: [],
    assetStatusesForKeyModal: [],
    vehicleActivitiesForKeyModal: []
};

KeyModal.propTypes = {
    showKeyModal: PropTypes.bool.isRequired,
    closeKeyModal: PropTypes.func,
    isInitialising: PropTypes.bool,
    ownershipForKeyModal: PropTypes.array.isRequired,
    assetStatusesForKeyModal: PropTypes.array.isRequired,
    vehicleActivitiesForKeyModal: PropTypes.array.isRequired
};