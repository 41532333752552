import React from "react";
import './Key.css';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Icon } from "@blueprintjs/core";
import { ShowHide } from "components/layout";

export function KeyBlock(props) {

    const fourColours = 4;
    const threeColours = 3;
    const twoColours = 2;

    const firstColour = 0;
    const secondColour = 1;
    const thirdColour = 2;
    const fourthColour = 3;

    function calculateColours(colours) {
        if (!Array.isArray(colours)) {
            return colours;
        }

        switch (colours.length) {
            case fourColours:
                return `${colours[firstColour]} ${colours[secondColour]} ${colours[thirdColour]} ${colours[fourthColour]}`;
            case threeColours:
                return `${colours[firstColour]} ${colours[firstColour]} ${colours[secondColour]} ${colours[thirdColour]}`;
            case twoColours:
                return `${colours[firstColour]} ${colours[firstColour]} ${colours[secondColour]} ${colours[secondColour]}`;
            default:
                return colours[firstColour];
        }
    }

    function handleClick() {
        props.onClick();
    }

    return (
        <div className={classNames("key-item-container", props.className)} style={{ width: props.size * 2, height: props.size * 2 }} aria-hidden="true" onClick={handleClick}>
            <div className={classNames("key-item", { "loading": props.loading, "rounded": props.rounded, "empty": props.colours?.length === 0, "selectable": props.selectable, "selected": props.selected, "key-item-border": !props.hideBorder })} style={{ "borderWidth": props.size, "borderColor": calculateColours(props.colours) }}></div>
            <ShowHide evaluator={props.icon !== null}
                show={(
                    <div className="key-icon">
                        <Icon icon={props.icon} iconSize={(props.size * 2) - 6} color={props.iconColour} />
                    </div>
                )}
            />
        </div>
    )
}

KeyBlock.defaultProps = {
    size: 10,
    rounded: false,
    icon: null,
    selectable: false,
    selected: false,
    loading: false,
    onClick: () => { },
    iconColour: "#FFFFFF",
    hideBorder: false,
};

KeyBlock.propTypes = {
    colours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    size: PropTypes.number,
    rounded: PropTypes.bool,
    icon: PropTypes.string,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    iconColour: PropTypes.string,
    hideBorder: PropTypes.bool
};