import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Intent } from '@blueprintjs/core';
import { DragDropArea } from "components/drag-drop";
import { FormCheckbox, FormSelect } from "components/form-fields";
import { Table } from "components/listing";
import { AlertUnsavedData, Modal, NotificationToaster } from 'components/notifications';
import { useDirtyData } from "hooks/useDirtyData";
import { Button, ButtonSave } from 'components/buttons';
import './PreferencesModal.css';

export function PreferencesModal(props) {
    const {
        isOpen, onClose, displayPreferences, cancelButtonIntent, onSave,
        onSavePreferences, loading, onPreferencesSaved,
        preferencesKey, numberOfWeeks,
    } = props;

    const [localDisplayPreferences, setLocalDisplayPreferences] = useState(displayPreferences);
    const [showAlert, setShowAlert] = useState(false);
    const [saving, setSaving] = useState(false);
    const [checkBoxDetails, setCheckBoxDetails] = useState([]);
    const [dragDropItems, setDragDropItems] = useState([]);

    const [localNumberOfWeeks, setLocalNumberOfWeeks] = useState(numberOfWeeks);

    const isDataDirty = useDirtyData(
        [localNumberOfWeeks, ...localDisplayPreferences.filter(option => option.userSelected).sort((a, b) => a.userPriority - b.userPriority).map(option => option.id)],
        [numberOfWeeks, ...displayPreferences.filter(option => option.userSelected).sort((a, b) => a.userPriority - b.userPriority).map(option => option.id)],
        true
    );

    const preferencesHeaders = {
        headers: [
            { key: 'COLUMN', value: 'Column' },
            { key: 'SELECT', value: 'Select' }
        ]
    };

    function onWeekPreferenceChange(selectedOption) {
        setLocalNumberOfWeeks(selectedOption.id);
    };

    function resetLocalState() {
        setLocalDisplayPreferences(displayPreferences);
        setLocalNumberOfWeeks(numberOfWeeks);
    };

    useEffect(() => {
        if (displayPreferences) {
            setLocalDisplayPreferences(displayPreferences);
        }

        if (numberOfWeeks) {
            setLocalNumberOfWeeks(numberOfWeeks);
        }
    }, [displayPreferences, numberOfWeeks]);

    useEffect(() => {
        setDragDropItems(localDisplayPreferences
            .filter(option => option.userSelected)
            .sort((a, b) => a.userPriority - b.userPriority)
            .map((item, index) => ({
                id: item.id,
                name: item.name,
                order: index
            })));
    }, [localDisplayPreferences])

    useEffect(() => {
        setCheckBoxDetails(localDisplayPreferences
            .sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            })
            .map(p => {
                return {
                    key: p.id.toString(),
                    cells: [
                        p.name,
                        <div className="checkbox-cell" key={`checkbox-${p.id}`}>
                            <FormCheckbox
                                checked={p.userSelected}
                                onChange={() => handleCheckboxChange(p.id)}
                                disabled={saving}
                            />
                        </div>
                    ],
                    class: p.userSelected ? 'selected' : 'not-selected'
                }
            }))
    }, [localDisplayPreferences]);

    function onDragEnd(newItems) {
        setLocalDisplayPreferences(
            localDisplayPreferences.map((item) => {
                const foundItem = newItems.find((newItem) => newItem.id === item.id);
                if (foundItem) {
                    return { ...item, userPriority: foundItem.order };
                }
                return item;
            }));
    }

    function onRemoveItem(id) {
        setLocalDisplayPreferences((prevPreferences) =>
            prevPreferences.map((option) =>
                option.id.toString() === id
                    ? { ...option, userSelected: false }
                    : option
            )
        );
    };

    function handleCheckboxChange(id) {
        setLocalDisplayPreferences(
            localDisplayPreferences.map((item) => {
                if (item.id === id) {
                    return { ...item, userSelected: !item.userSelected, userPriority: Math.max(...localDisplayPreferences.map(o => o.userPriority)) + 1 };
                }
                return item;
            })
        );
    };

    function handleConfirmLeave() {
        resetLocalState();
        onClose();
        setShowAlert(false);
    };

    function handleCancelLeave() {
        setShowAlert(false);
    };

    function handleSave() {
        let preferences;
        if (preferencesKey === "Tacho") {
            preferences = {
                tachoWeeksToShow: localNumberOfWeeks,
                userDisplayOptions: localDisplayPreferences
                    .filter(option => option.userSelected)
                    .sort((a, b) => a.userPriority - b.userPriority)
                    .map(option => option.id)
            };
        } else {
            preferences = {
                widgets: localDisplayPreferences
                    .filter(option => option.userSelected)
                    .sort((a, b) => a.userPriority - b.userPriority)
                    .map(option => option.id)
            };
        }

        if (isDataDirty) {
            setSaving(true);
            onSavePreferences(preferences)
                .then(
                    () => {
                        NotificationToaster.show(Intent.SUCCESS, `${preferencesKey} preferences were saved successfully.`);
                        setSaving(false);
                        if (preferencesKey === "Tacho") {
                            onSave(localDisplayPreferences.sort((a, b) => a.userPriority - b.userPriority), localNumberOfWeeks);
                        } else {
                            onSave(localDisplayPreferences.sort((a, b) => a.userPriority - b.userPriority));
                        }

                        onClose();
                        onPreferencesSaved();
                    }
                )
                .catch(
                    (error) => {
                        NotificationToaster.show(Intent.DANGER, `Failed to save ${preferencesKey} preferences. Please try again. ${error}`);
                        setSaving(false);
                    }
                );
        } else {
            setSaving(false);
            onClose();
        }
    };

    function closePreferencesModal() {
        if (isDataDirty) {
            setShowAlert(true);
        } else {
            onClose();
            resetLocalState();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            title="Column Preferences"
            isCloseButtonShown={false}
            onClose={closePreferencesModal}
            autoFocus={false}
            enforceFocus={false}
            canOutsideClickClose={false}
            wide={true}
            updateStateOnClose={false}
        >
            <div className="preferences-modal-body form-field form-field-select">
                <div className="preferences-content">
                    {preferencesKey === "Tacho" && (
                        <FormSelect
                            headingText="Please select how many weeks of data you would like to see."
                            items={Array.from({ length: 4 }, (_, i) => i + 1).map(m => ({ id: m, name: `${m} weeks` }))}
                            placeholder="Items"
                            onItemSelect={onWeekPreferenceChange}
                            selectedValue={localNumberOfWeeks}
                            disabled={loading}
                            dynamicWidth={true}
                        />
                    )}
                    <h4>Please select from the list below:</h4>
                </div>
                <div className="table-container spacer-bottom">
                    <Table
                        headerData={preferencesHeaders}
                        data={checkBoxDetails}
                        loadingData={false}
                        fitToContent={false}
                        className="preferences-table"
                    />
                </div>
                <h4>Drag the elements below to arange them in the order you would like to see:</h4>
                <div>
                    <DragDropArea
                        onDragEnd={onDragEnd}
                        items={dragDropItems}
                        onRemoveItem={onRemoveItem}
                        disabled={saving}
                        isSaving={saving}
                    />
                </div>
                <div className="button-row">
                    <Button
                        text="CANCEL"
                        intent={cancelButtonIntent}
                        onClick={closePreferencesModal}
                        disabled={saving}
                        className="cancel-button"
                    />
                    <ButtonSave
                        text="SAVE AND CLOSE"
                        intent={Intent.PRIMARY}
                        onClick={handleSave}
                        loading={loading}
                        disabled={saving}
                        icon="floppy-disk"
                    />
                    {showAlert && (
                        <AlertUnsavedData
                            isDirty={isDataDirty}
                            isDirtySamePage={isDataDirty}
                            onConfirmLeave={handleConfirmLeave}
                            onCancelLeave={handleCancelLeave}
                        />
                    )}
                </div>
            </div>

        </Modal>
    );
}

PreferencesModal.defaultProps = {
    isOpen: false,
    onClose: () => { },
    displayPreferences: [],
    cancelButtonIntent: Intent.NONE,
    onSave: () => { },
    loading: false,
    onPreferencesSaved: () => { },
    onLoadPreferences: () => Promise.resolve(),
    onSavePreferences: () => Promise.resolve(),
    preferencesKey: "",
    numberOfWeeks: 1
};

PreferencesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    displayPreferences: PropTypes.array.isRequired,
    cancelButtonIntent: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onSavePreferences: PropTypes.func.isRequired,
    onLoadPreferences: PropTypes.func.isRequired,
    onPreferencesSaved: PropTypes.func.isRequired,
    preferencesKey: PropTypes.string.isRequired,
    numberOfWeeks: PropTypes.number.isRequired,
};
