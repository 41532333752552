import React, { useEffect, useState } from 'react';
import { Modal, NotificationToaster } from 'components/notifications';
import { Tabs } from 'components/navigation';
import { ActivitiesTab } from 'pages/fleet/day-detail-modal/activities-tab';
import { SpeedDataTab } from 'pages/fleet/day-detail-modal/speed-data-tab';
import { HistoryTab } from 'pages/fleet/day-detail-modal/history-tab';
import { NotesTab } from 'pages/fleet/day-detail-modal/notes-tab';
import { AssetService } from 'services';
import PropTypes from "prop-types";
import { Intent } from '@blueprintjs/core';
import { AssetStatus } from 'components/status/AssetStatus';
import { ShowHide } from 'components/layout';
import { FormHeading } from 'components/form-fields';
import { DateToLocal } from 'components/dates';

export function DayDetailModal(props) {
    const { showDayDetailModal, closeDayDetailModal, assetId, date, statusAttributeMappings, ownershipAttributeMappings } = props;
    const [assetStatus, setAssetStatus] = useState(0);
    const [assetOwnership, setAssetOwnership] = useState(0);
    const [assetRegistration, setAssetRegistration] = useState("");
    const [tachoDataForDayDetail, setTachoDataForDayDetail] = useState({});
    const [isTachoDataLoading, setIsTachoDataLoading] = useState(false);
    const [harshBrakingData, setHarshBrakingData] = useState([]);
    const [dayDetailTitle, setDayDetailTitle] = useState(<></>)
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("ACTIVITIES");
    const [dayDetailDate, setDayDetailDate] = useState()
    const [tabs, setTabs] = useState([
        { title: "Activities", selected: true, key: "ACTIVITIES" },
        { title: "History", selected: false, key: "HISTORY" },
        { title: "Notes", selected: false, key: "NOTES" }
    ]);
    const [note, setNote] = useState("");

    useEffect(() => {
        if (!showDayDetailModal) {
            resetTabData();
        }
        else if (assetId && date && showDayDetailModal) {
            setDayDetailDate(date);
            callTachDataForDayDetail(date);
            callHarshDataForDayDetail(date);
            callNoteForDayDetail(date);
        }

    }, [assetId, date, showDayDetailModal]);

    useEffect(() => {
        if (note) {
            setTabs(tabs.map(function (t, i) {
                if (t.key === "NOTES") {
                    return {
                        ...t,
                        icon: "comment"
                    }
                }
                return t;
            }));
        }
        else {
            setTabs(tabs.map(function (t, i) {
                if (t.key === "NOTES") {
                    return {
                        title: t.title,
                        selected: t.selected,
                        key: t.key
                    }
                }
                return t;
            }));
        }
    }, [note]);

    useEffect(() => {
        setDayDetailTitle(
            <div className="inline-items">
                <FormHeading headingLevel="h2">Day Detail</FormHeading>
                <FormHeading headingLevel="h2">{<DateToLocal format='DD MMM YYYY'>{dayDetailDate}</DateToLocal>}</FormHeading>
                <AssetStatus statusId={assetStatus} ownershipId={assetOwnership} isLoading={loading && isTachoDataLoading} statusAttributeMappings={statusAttributeMappings} ownershipAttributeMappings={ownershipAttributeMappings} />
                <FormHeading headingLevel="h2" isLoading={loading && isTachoDataLoading}><a href={`${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${assetId}`}>{assetRegistration}</a></FormHeading>

            </div>)
    }, [assetStatus, assetOwnership, assetRegistration, loading, isTachoDataLoading, statusAttributeMappings, ownershipAttributeMappings, dayDetailDate]);

    function callTachDataForDayDetail(dateForTachoData) {
        setIsTachoDataLoading(true);
        AssetService.getTachoDataForAsset(assetId, dateForTachoData).then((response) => {
            if (response != null) {
                setTachoDataForDayDetail(response);
                setAssetStatus(response.vehicleStatus);
                setAssetOwnership(response.vehicleOwnership);
                setAssetRegistration(response.vehicleRegistration);

                if (response.hasSpeedData) {
                    setTabs([
                        { title: "Activities", selected: true, key: "ACTIVITIES" },
                        { title: "Speed Data", selected: false, key: "SPEED_DATA" },
                        { title: "History", selected: false, key: "HISTORY" },
                        { title: "Notes", selected: false, key: "NOTES" }
                    ]);
                }
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get tacho data");
            setIsTachoDataLoading(false);
        }).finally(() => {
            setLoading(false);
            setIsTachoDataLoading(false);
        });
    }

    function callHarshDataForDayDetail(dateForHarshBreaking) {
        setLoading(true);
        AssetService.getHarshBrakingDataForAsset(assetId, dateForHarshBreaking).then((response) => {
            if (response != null) {
                setHarshBrakingData(response);
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get harsh braking data", false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function callNoteForDayDetail(dateForNotes) {
        AssetService.getNote(assetId, dateForNotes).then((response) => {
            if (response && response.note) {
                setNote(response.note);
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, `Failed to get note`, false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function onTabClick(index) {
        setTabs(tabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        }));

        setSelectedTab(tabs[index].key);
    }

    function resetTabs() {
        setSelectedTab("ACTIVITIES");
        setTabs(tabs.map(function (t, i) {
            return {
                title: t.title,
                selected: i === 0,
                key: t.key
            }
        }));
        setNote("");
        setAssetStatus(0);
        setAssetOwnership(0);
        setAssetRegistration("");
        setTachoDataForDayDetail({});
        setHarshBrakingData([]);
    }

    function onDateChanged(newDate) {
        if (newDate === dayDetailDate) { return; }
        resetTabData(newDate);
    }

    function resetSpeedDataTab(newDate) {
        resetTabData(newDate);
    }

    function resetTabData(newDate) {
        if (newDate) {
            setDayDetailDate(newDate);
            callTachDataForDayDetail(newDate);
            callHarshDataForDayDetail(newDate);
            callNoteForDayDetail(newDate);
            setSelectedTab("ACTIVITIES");
            setTabs([
                { title: "Activities", selected: true, key: "ACTIVITIES" },
                { title: "History", selected: false, key: "HISTORY" },
                { title: "Notes", selected: false, key: "NOTES" }
            ]);
        }
    }

    function closeModal() {
        resetTabs();
        closeDayDetailModal();
    }

    return (
        <Modal
            isOpen={showDayDetailModal}
            clickOutsideClose
            onClose={closeModal}
            title={dayDetailTitle}
            icon="warning-sign"
            intent={Intent.DANGER}
            confirmButtonText="Dismiss"
            onConfirm={props.closeDayDetailModal}
            fullScreen
        >

            <Tabs tabs={tabs} onClick={onTabClick} loading={loading || props.loading} />

            <ShowHide
                evaluator={selectedTab === "ACTIVITIES"}
                show={<ActivitiesTab
                    lastSearchPayload={props.lastSearchPayload}
                    activitiesApiResponse={tachoDataForDayDetail}
                    harshBrakingApiResponse={harshBrakingData}
                    isLoading={isTachoDataLoading}
                    vehicleActivityTypes={props.vehicleActivityTypes}
                    assetDetails={{ assetId, assetStatus, assetOwnership, assetRegistration, statusAttributeMappings, ownershipAttributeMappings }}
                    date={dayDetailDate}
                    onDateChanged={onDateChanged}
                />}
            />

            <ShowHide
                evaluator={selectedTab === "SPEED_DATA"}
                show={<SpeedDataTab
                    assetId={assetId}
                    date={dayDetailDate}
                    resetTabs={resetSpeedDataTab}
                    harshBrakingData={harshBrakingData} />
                }
            />

            <ShowHide
                evaluator={selectedTab === "HISTORY"}
                show={<HistoryTab />}
            />

            <ShowHide
                evaluator={selectedTab === "NOTES"}
                show={<NotesTab
                    assetId={assetId}
                    date={dayDetailDate}
                    note={note}
                    setNote={setNote} />
                }
            />
        </Modal>
    )
}

DayDetailModal.defaultProps = {
    showDayDetailModal: false,
    closeDayDetailModal: () => { },
    assetId: 0,
    date: "",
    statusAttributeMappings: [],
    ownershipAttributeMappings: []
};

DayDetailModal.propTypes = {
    showDayDetailModal: PropTypes.bool.isRequired,
    closeDayDetailModal: PropTypes.func,
    assetId: PropTypes.number,
    date: PropTypes.string,
    statusAttributeMappings: PropTypes.array,
    ownershipAttributeMappings: PropTypes.array,
    vehicleActivityTypes: PropTypes.array.isRequired,
    lastSearchPayload: PropTypes.object
};
