import React from "react";
import {Panel} from 'components/panel';
import PropTypes from "prop-types";
import moment from "moment";
import './HarshBrakingPanel.css';
import classNames from 'classnames';

export function HarshBrakingPanel(props) {
    return (
        <Panel className={classNames("harsh-braking-panel", props.panelClassNames)} color={props.leftColor} disabled={props.disabled} >
            <h3 className="harsh-braking-header">Harsh Braking</h3>
            <div className="harsh-braking-content">
                {
                    props.items.map((i) => {
                    return (<Panel key={moment(i.startDate).format("MMYYHHmmss")} className="harsh-braking-panel-item">
                                <h4>{moment(i.startDate).format("DD MMM YYYY HH:mm:ss")}</h4>
                                <table className="harsh-braking-panel-item-table">
                                    <tbody>
                                        <tr>
                                            <th>Start Speed</th>
                                            <th>End Speed</th>
                                            <th>Duration</th>
                                        </tr>
                                        <tr>
                                            <td>{String(i.startSpeed).padStart(2, '0')}kph</td>
                                            <td>{String(i.endSpeed).padStart(2, '0')}kph</td>
                                            <td>{i.duration}s</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </Panel>);
                    })
                }
            </div>
        </Panel>
    );
}

HarshBrakingPanel.defaultProps = {
    disabled: false,
    items: []
};

HarshBrakingPanel.propTypes = {
    leftColor: PropTypes.string,
    items: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    panelClassNames: PropTypes.string
};