import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { DateToLocal } from "components/dates";
import { DataDisplayTable } from "components/data-display";
import { Collapsable, ShowHide } from "components/layout";
import { Numeric } from "components/formatting";
import { AssetService } from "services";
import { StatusBlock } from "components/status";
import { ListingTable } from "components/listing";
import { NotificationToaster } from "components/notifications";
import classNames from "classnames";
import { ButtonSave } from "components/buttons";

export function MotTests(props) {

    const requireActions = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const [pageHeaders, setPageHeaders] = useState([]);
    const [motTests, setMotTests] = useState([]);
    const defectTableHeaders = ["Defect Type", "Comment"];
    const [loading, setLoading] = useState(true);
    const [rechecking, setRechecking] = useState(false);
    const [completedCheck, setCompletedCheck] = useState(false);
    const userCanEditAsset = UserHasAccess(["Fleet:Edit", "FleetPCN:Edit", "FleetVIS:Edit"]);

    function init() {
        if (props.assetId != null) {

            setLoading(true);
            AssetService.getMotTests(props.assetId).then(function (motResponse) {

                if (motResponse.length > 0) {

                    var latestTest = motResponse[0];

                    setPageHeaders([
                        {
                            header: "Current MOT Status",
                            value: generateStatusBlock(latestTest.passed, latestTest.defects),
                            key: 'mot-status'
                        },
                        {
                            header: "Last MOT Date",
                            value: (
                                <DateToLocal format="DD MMMM YYYY">
                                    {latestTest.testDate}
                                </DateToLocal>
                            ),
                            key: 'last-date'
                        },
                        {
                            header: "MOT Expiry Date",
                            value: <DateToLocal format="DD MMMM YYYY">
                                {latestTest.expiryDate}
                            </DateToLocal>,
                            key: 'expiry-date'
                        },
                        {
                            header: "Last Check Date",
                            value: <DateToLocal format="DD MMMM YYYY">
                                {latestTest.expiryDate}
                            </DateToLocal>,
                            key: 'sync-date'
                        }
                    ]);

                    setMotTests(motResponse.map((test, i) => {
                        return {
                            ...test,
                            expanded: i === 0
                        }
                    }));

                }

                setLoading(false);

            }, function () {
                NotificationToaster.show(Intent.DANGER, "MOT data could not be fetched, please try again");
            });
        }
    };

    function generateStatusBlock(passed, defects) {
        return <StatusBlock status={passed ? "green" : "red"}>{getStatusText(passed, defects)}</StatusBlock>
    }

    function getStatusText(passed, defects) {
        if (!passed) {
            return "Failed";
        }

        if (defects != null && defects.length > 0) {
            return "Passed with defects";
        }

        return "Passed";
    }

    useEffect(init, [props.assetId]);

    function onCollapsableClick(index) {
        setMotTests(function (previous) {
            var clonedTests = [...previous];
            clonedTests[index].expanded = !clonedTests[index].expanded;
            return clonedTests;
        })
    }

    function canPerformMotCheck() {

        const validCheckDayPeriod = 6;

        if (!userCanEditAsset || props.asset == null || completedCheck || props.asset.registration == null) {
            return false;
        }

        if (props.asset.nextGovApiCheckTime != null) {

            var checkDate = moment(props.asset.nextGovApiCheckTime);
            if (moment().add(validCheckDayPeriod, 'day').isBefore(checkDate)) {
                return false
            }
        }

        return true;
    }

    function onMotCheck() {
        setRechecking(true);

        AssetService.performMotCheck(props.assetId).then(function () {
            init();
            setCompletedCheck(true);
            NotificationToaster.show(Intent.SUCCESS, "The lastest MOT information has been fetched successfully");
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Could not perform an MOT check, please try again later");
        }).finally(function () {
            setRechecking(false);
        });
    }

    return (

        <UserAccess perform={requireActions}
            yes={() => (

                <div className="row">

                    <div className="spacer-bottom">
                        <h1>MOT Tests</h1>
                        <ShowHide
                            evaluator={canPerformMotCheck()}
                            show={(
                                <ButtonSave text="Re-check for new MOTs" intent="primary" icon="refresh" disabled={rechecking} onClick={onMotCheck} loading={loading} />
                            )}
                        />
                    </div>

                    <ShowHide
                        evaluator={motTests.length === 0}
                        show={<p className={classNames("intro-text", { "bp3-skeleton": loading })} >There are no MOT tests available for this asset.</p>}
                        hide={(
                            <Fragment>
                                <DataDisplayTable
                                    data={pageHeaders}
                                    displayInRows={false}
                                    loading={loading}
                                    columnsPerRow={pageHeaders.length}
                                />

                                {motTests && motTests.map(function (test, testIndex) {
                                    return (
                                        <Collapsable
                                            key={test.testDate}
                                            loading={loading}
                                            title={<DateToLocal format="DD MMMM YYYY - HH:mm">{test.testDate}</DateToLocal>}
                                            fullWidth
                                            expanded={test.expanded}
                                            onClick={() => onCollapsableClick(testIndex)}
                                            metadata={generateStatusBlock(test.passed, test.defects)}
                                        >
                                            <div className="spacer-bottom">
                                                <DataDisplayTable
                                                    data={[
                                                        {
                                                            header: "Test Expiry Date",
                                                            value: <DateToLocal format="DD MMMM YYYY">
                                                                {test.expiryDate}
                                                            </DateToLocal>,
                                                            key: 'expiry-date'
                                                        },
                                                        {
                                                            header: "Test Location",
                                                            value: test.location,
                                                            key: 'test-location'
                                                        },
                                                        {
                                                            header: "Test Odo Reading",
                                                            value: test.odoReading != null ? <span><Numeric>{test.odoReading}</Numeric> km</span> : "Unknown",
                                                            key: 'odo-reading'
                                                        }
                                                    ]}
                                                    displayInRows={false}
                                                    loading={loading}
                                                    columnsPerRow={3}
                                                />

                                                <ShowHide
                                                    evaluator={test.defects != null && test.defects.length > 0}
                                                    show={(
                                                        <div>
                                                            <p className="intro-text">The following defects were raised as a result of your test:</p>
                                                            <ListingTable
                                                                headers={defectTableHeaders}
                                                                pageable={false}
                                                                loadingData={loading}
                                                                totalRecordCount={test.defects.length}
                                                                data={test.defects && test.defects.map(function (defect) {
                                                                    return [defect.type, defect.text]
                                                                })}
                                                            />
                                                        </div>
                                                    )}
                                                />

                                            </div>
                                        </Collapsable>
                                    );
                                })}
                            </Fragment>
                        )}
                    />

                </div>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}

MotTests.defaultProps = {
    
};

MotTests.propTypes = {
    assetId: PropTypes.string.isRequired,
    asset: PropTypes.object.isRequired
};