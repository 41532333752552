import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Icon } from "@blueprintjs/core";
import './Layout.css';
import { ShowHide } from './ShowHide';
import classNames from 'classnames';

export function Collapsable(props) {
    return (

        <div className={classNames("collapsable-item", { "full-width": props.fullWidth })}>
            <div className={classNames("collapsable-header", { "bp3-skeleton": props.loading })} onClick={props.onClick}>
                <div className="collapsable-title">
                    {props.title}
                </div>

                <div className="collapsable-icons button-row">
                    <span className="collapsable-metadata">
                        {props.metadata}
                    </span>
                    <ShowHide
                        evaluator={!props.disabled}
                        show={props.headerButtons}
                    />
                    <Icon className="collapsable-icon-status" icon={props.expanded ? "chevron-up" : "chevron-down"} />
                </div>
            </div>
            <Collapse isOpen={props.expanded}>
                {props.children}
            </Collapse>
           
        </div>


    );
}

Collapsable.defaultProps = {
    loading: false,
    disabled: false,
    title: '',
    fullWidth: false,
    expanded: false,
    metadata: ''
};

Collapsable.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    headerButtons: PropTypes.node,
    fullWidth: PropTypes.bool,
    children: PropTypes.node,
    expanded: PropTypes.bool,
    metadata: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func.isRequired
};