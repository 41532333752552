import React, { useEffect, useRef, useState } from "react";
import { InstantReportButton } from "components/reporting/InstantReportButton";
import { AssetService, SelfReportingService } from 'services';
import { Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { NotificationToaster } from "components/notifications";
import { Dropdown } from "components/dropdown";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export function ReportList(props) {
    const reportAttributeMappings = [
        { "type": "Excel", "icon": "fa fa-file-excel-o" },
        { "type": "PDF", "icon": "fa fa-file-pdf-o" },
        { "type": "CSV", "icon": "fa fa-file-code-o" }
    ];

    const [reportTypeList, setReportTypeList] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [generatingReportInstanceId, setGeneratingReportInstanceId] = useState();
    const [reportDownloading, setReportDownloading] = useState(false);
    const generationCancelTokenSource = useRef();

    useEffect(() => {
        callTachoReports();
    }, []);

    useEffect(() => {
        const reportTypeButtons = reportTypeList.map((x) => {
            return <InstantReportButton buttonType="MenuItem" key={x.id} id={x.id.toString()} text={x.name} icon={<i className={x.icon} ></i>} onReportGenerate={() => onReportGenerate(x.id)} onReportDownloadComplete={onReportDownloadComplete} reportId={generatingReportInstanceId} />
        });
        setReportTypes(reportTypeButtons);
    }, [props.lastSearchPayload, reportTypeList]);

    useEffect(() => {
        if (generatingReportInstanceId != null) {
            SelfReportingService.getInstantReport(generatingReportInstanceId, generationCancelTokenSource, notifyResponse);
        }
    }, [generatingReportInstanceId]);

    function callTachoReports() {
        AssetService.getTachoReports(props.pageName).then((response) => {
            setReportTypeList(formatReportTypes(response));
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Report Types. ${error}`);
        });
    }

    function formatReportTypes(types) {
        return types.map((x) => {
            const mappingItem = reportAttributeMappings.find(y => y.type === x.type);
            return { "id": x.id, "name": x.name, "icon": mappingItem.icon };
        });
    }

    function notifyResponse(response) {
        onReportDownloadComplete();
        if (response) {
            NotificationToaster.show(
                Intent.SUCCESS,
                "Report has been generated successfully, download will now begin"
            );
        }
        else {
            NotificationToaster.show(
                Intent.DANGER,
                "Could not generate the report. Please try again.");
        }
    }

    function onReportDownloadComplete() {
        setGeneratingReportInstanceId(null);
        setReportDownloading(false);
        props.onReportDownloadingChange(false);
    }

    function onReportGenerate(p) {
        setReportDownloading(true);
        props.onReportDownloadingChange(true);
        AssetService.requestTachoReport(p, props.pageName, props.lastSearchPayload).then((result) => {
            setGeneratingReportInstanceId(result);
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to download report. ${error}`);
            onReportDownloadComplete();
        });
    }

    const reportDropdownPreamble = (
        <>
            <h4>Downloads</h4>
            <p>
                For reports requiring date ranges only the data shown on the calendar will be included.<br /> For more detailed reporting visit <Link to='/self-service-reports'>Self-Service Reports</Link>
            </p>
        </>
    );
    return (
        <Dropdown intent="primary" text="Reports" id="reports" items={reportTypes} preamble={reportDropdownPreamble} disabled={reportDownloading} popoverUsePortal={props.popoverUsePortal} />
    );
}

ReportList.defaultProps = {
    lastSearchPayload: {},
    popoverUsePortal: true,
    onReportDownloadingChange: () => { }
};

ReportList.propTypes = {
    lastSearchPayload: PropTypes.object,
    pageName: PropTypes.string,
    popoverUsePortal: PropTypes.bool,
    onReportDownloadingChange: PropTypes.func
};