import React, { Fragment, useState, useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';

import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess } from 'components/user-access';
import { Breadcrumbs, InternalLink, Tabs } from 'components/navigation';
import { FormSwitch, FormTextInput } from 'components/form-fields';
import { DataDisplayTable } from 'components/data-display';
import { RiskService } from 'services/RiskService';
import { DateToLocal } from 'components/dates';
import { Button, LinkButton } from 'components/buttons';
import { StatusBlock } from 'components/status';
import { Tooltip } from 'components/tooltip';
import { TrendStatus } from 'components/status/TrendStatus';
import { ShowHide } from 'components/layout';
import { ListingTable } from 'components/listing';
import { Alignment, Intent, NonIdealState } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { HelperFunctions } from 'helpers';
import { NotificationToaster } from 'components/notifications';
import { useDebounce } from 'hooks/useDebounce';
import { RiskSelector } from 'pages/risk/shared/RiskSelector';
import { Numeric } from 'components/formatting';

export function RiskOverview(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [riskEnabled, setRiskEnabled] = useState(true);
    const [riskStarted, setRiskStarted] = useState(true);
    const [nextPeriodStartDate, setNextPeriodStartDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [changingPeriod, setChangingPeriod] = useState(false);
    const [periodInfo, setPeriodInfo] = useState([]);
    const [riskAreas, setRiskAreas] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const cachedPreferences = useSelector(state => state.filters["riskSearch"]);
    const [trendGraphs, setTrendGraphs] = useState([]);
    const [selectedGraph, setSelectedGraph] = useState(null);
    const [displayGraph, setDisplayGraph] = useState(false);
    const [graphBands, setGraphBands] = useState([]);
    const [showGraphBands, setShowGraphBands] = useState(false);
    const [locationTableData, setLocationTableData] = useState([]);
    const [locationTableRecordCount, setLocationTableRecordCount] = useState(0);
    const [loadingLocationList, setLoadingLocationList] = useState(true);

    const locationTableHeaders = ["Location", "Risk Score", "Employee Risk Score", "Asset Risk Score"];
    const locationSortableHeaders = [
        { headerName: "Location", sortName: "LOCATION" },
        { headerName: "Risk Score", sortName: "LOCATIONSCORE" },
        { headerName: "Employee Risk Score", sortName: "EMPLOYEESCORE" },
        { headerName: "Asset Risk Score", sortName: "ASSETSCORE" },
     ];

    const [locationPageNumber, setLocationPageNumber] = useState(1);
    const [locationPageSize, setLocationPageSize] = useState(defaultRecordSize);
    const [locationSearchTerm, setLocationSearchTerm] = useState("");
    const [locationTableSortBy, setLocationTableSortBy] = useState(locationSortableHeaders[1]);
    const [locationTableSortDir, setLocationTableSortDir] = useState("D");

    const debouncedLocationSearchTerm = useDebounce(locationSearchTerm, debounceTimeout);
    const currentLocationPageNumber = useRef();
    const currentLocationPageSize = useRef();
    const currentLocationSearchTerm = useRef();
    const currentLocationTableSortBy = useRef();
    const currentLocationTableSortDir = useRef();

    function init() {

        RiskService.getGraphBands().then(function (bandsResponse) {
            setGraphBands(bandsResponse);
        });
    }

    useEffect(init, []);

    function onSearchLoad(searchPreferences, riskStatus) {

        setSelectedLocations(searchPreferences.locations);
        setSelectedPeriod(searchPreferences.period);
        setRiskEnabled(riskStatus.enabled);
        setRiskStarted(riskStatus.lastCompletePeriod != null);
        setNextPeriodStartDate(riskStatus.nextPeriodStartDate);
    }

    function fetchPeriodInfo(requestedLocations, requestedPeriod) {

        setChangingPeriod(true);

        RiskService.getPeriodInfo(requestedLocations, requestedPeriod).then(function (periodInfoResponse) {

            if (periodInfoResponse === "") {
                return;
            }

            mapRiskAreas(periodInfoResponse);
         
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Could not load period, please try again");
        }).finally(function () {
            setLoading(false);
            setChangingPeriod(false);
        });
    }

    useEffect(function () {

        if (selectedLocations != null && selectedLocations.length > 0 && selectedPeriod !== 0) {
            fetchPeriodInfo(selectedLocations, selectedPeriod);
            fetchTrends(selectedLocations);
            fetchLocationScores(true);
        }
    }, [selectedLocations, selectedPeriod])

    useEffect(function () {

        if (!loading) {
            setSelectedLocations(cachedPreferences.locations);
            setSelectedPeriod(cachedPreferences.period);
        }

    }, [cachedPreferences]);

    function onShowGraphRiskBandsChange() {
        setShowGraphBands(function (prev) {
            return !prev;
        });
    }

    function fetchTrends(requestedLocations) {

        if (selectedPeriod === 0) {
            return;
        }

        RiskService.getTrends(requestedLocations).then(function (trendResponse) {
            mapTrends(trendResponse);
        }, function () {
            setTrendGraphs([]);
            setSelectedGraph(null);
            setDisplayGraph(false);
        });
    }

    function mapTrends(trendResponse) {

        var mappedGraphs = trendResponse.map(function (area, areaIndex) {

            var orderedScores = area.scores.sort((a, b) => a.periodNumber - b.periodNumber);

            var graphPeriods = orderedScores.map((score) => score.periodNumber);
            var locationScores = orderedScores.map((score) => score.locationScore);
            var companyScores = orderedScores.map((score) => score.companyScore);

            var trendLines = [];
            trendLines.push(locationScores.length > 0 ? locationScores : []);
            trendLines.push(companyScores.length > 0 ? companyScores : []);

            return {
                title: area.areaName,
                selected: areaIndex === 0,
                key: area.areaName,
                graphPeriods: graphPeriods,
                trendLines: trendLines,
                disabled: graphPeriods.length === 0
            }
        });

        setDisplayGraph(mappedGraphs.length > 0 && mappedGraphs[0].graphPeriods.length > 1);
        setTrendGraphs(mappedGraphs);
        setSelectedGraph(mappedGraphs[0]);

    }

    function fetchLocationScores(forceRefresh = false) {

        if (selectedPeriod === 0) {
            return;
        }

        if (currentLocationPageNumber.current === locationPageNumber &&
            currentLocationPageSize.current === locationPageSize &&
            currentLocationSearchTerm.current === debouncedLocationSearchTerm &&
            currentLocationTableSortBy.current === locationTableSortBy.sortName &&
            currentLocationTableSortDir.current === locationTableSortDir &&
            !forceRefresh
        ) {
            return;
        }

        currentLocationPageNumber.current = locationPageNumber;
        currentLocationPageSize.current = locationPageSize;
        currentLocationSearchTerm.current = debouncedLocationSearchTerm;
        currentLocationTableSortBy.current = locationTableSortBy.sortName;
        currentLocationTableSortDir.current = locationTableSortDir;

        setLoadingLocationList(true);

        RiskService.getLocationScores(locationPageSize, locationPageNumber, debouncedLocationSearchTerm, locationTableSortBy.sortName, locationTableSortDir, selectedLocations, selectedPeriod).then(function (locationsResponse) {
            mapLocationScores(locationsResponse);
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Could not fetch the location scores");
        }).finally(function () {
            setLoadingLocationList(false);
        });
    }

    function mapLocationScores(locationsResponse) {
        setLocationTableRecordCount(locationsResponse.totalCount);
        setLocationTableData(locationsResponse.data.map(function (l) {
            return [
                l.locationName,
                generateStatusBlock(l.score),
                generateStatusBlock(l.employeeScore),
                generateStatusBlock(l.assetScore)
            ];
        }));

    }

    function generateStatusBlock(riskScore) {

        if (riskScore?.band == null) {
            return <StatusBlock background="EFEFEF">No score available</StatusBlock>
        }

        return <StatusBlock background={riskScore.bandColour}><Numeric>{riskScore.total}</Numeric> - {riskScore.band}</StatusBlock>;
    }

    function onTrendClick(tabIndex) {
        var clonedGraphs = trendGraphs.map(function (c, index) {
            return {
                ...c,
                selected: index === tabIndex,
               
            }
        });

        setTrendGraphs(clonedGraphs);
        setSelectedGraph(clonedGraphs[tabIndex]);
    }

    function mapRiskAreas(periodInfoResponse) {

        var totalEvents = periodInfoResponse.areas.reduce((accumulator, area) => accumulator + area.eventsCaptured, 0);

        var currentPeriodInfo = [];
        currentPeriodInfo.push({ header: "Risk Period", value: <span className="inline-items"><h3>#{periodInfoResponse.periodNumber}</h3> <em style={{ 'fontSize': '13px' }}><DateToLocal format="DD/MM/YYYY">{periodInfoResponse.periodStartDate}</DateToLocal> - <DateToLocal format="DD/MM/YYYY">{periodInfoResponse.periodEndDate}</DateToLocal></em></span>, key: 'risk-period-dates' });
        currentPeriodInfo.push({ header: "Events Captured", value: <Numeric>{totalEvents}</Numeric>, key: 'risk-current-events' });

        var mappedAreas = periodInfoResponse.areas.map(function (area) {

            if (area.areaName !== "Organisation") {
                currentPeriodInfo.push({
                    header: `${area.areaName}s Analysed`, value: <Numeric>{area.entitiesAnalysed}</Numeric>, key: `risk-count-${area.areaName}` });
            }

            var clonedArea = { ...area };

            //check to see if there was a previous chart setting.
            var previousArea = riskAreas.find(x => x.areaName === clonedArea.areaName);
            clonedArea.showPieChart = previousArea != null ? previousArea.showPieChart : false;

            var chart = {};

            if (area.categories != null) {

                var previousScores = area.categories.map(c => c.previousPeriodScore);
                var periodScores = area.categories.map(c => c.periodScore);

                var maxScore = Math.max(...previousScores, ...periodScores);

                chart.pieData = area.categories.map(function (c) {
                    return { value: c.periodScore, name: c.name }
                });
                chart.spiderLegend = area.categories.map(function (c) {
                    return { name: c.name, max: maxScore };
                });

                chart.spiderData = [
                    {
                        value: previousScores,
                        name: `Period ${selectedPeriod - 1} Scores (Previous Period)`,
                        areaStyle: {
                            color: 'rgba(200, 200, 200, 0.3)'
                        }
                    },
                    {
                        value: periodScores,
                        name: `Period ${selectedPeriod} Scores`,
                        areaStyle: {
                            color: 'rgba(0, 140, 255, 0.3)'
                        }
                    }

                ]


            } else {
                chart.pieData = [];
                chart.spiderLegend = [];
                chart.spiderData = [];
            }

            clonedArea.chart = chart;

            return clonedArea;

        });

        setPeriodInfo(currentPeriodInfo);
        setRiskAreas(mappedAreas);
    }

    function onToggleChart(index) {
        setRiskAreas(function (previous) {
            var clonedAreas = [...previous];
            clonedAreas[index].showPieChart = !clonedAreas[index].showPieChart;
            return clonedAreas;
        });
    }

    function displayGraphLegend(values) {
        var htmlStr = `<strong>Period ${values[0].name}:</strong><br />`;
        values.forEach(function (value) {
            htmlStr += value.marker + `${value.seriesName}: ${value.value} - ${calculateRiskBand(value.value)}<br />`;
        });

        return htmlStr;
    }

    function calculateRiskBand(value) {
        if (value === 0) {
            return "Very Low Risk";
        }

        var matchedBand = graphBands.find(function (band) {
            return (value > band[0].yAxis && value <= band[1].yAxis);
        });

        return matchedBand != null ? matchedBand[0].name : "";
    }

    function onLocationTableSort(header, direction) {
        setLocationTableSortDir(direction);
        setLocationTableSortBy(header);
    }

    function onLocationPagingChange(newPageNumber, newPageSize) {
        setLocationPageNumber(newPageNumber);
        setLocationPageSize(newPageSize);
    }

    function onSearchChange(item) {
        setLocationSearchTerm(item.target.value);
    }

    useEffect(() => {
        fetchLocationScores();
    }, [locationPageSize, locationPageNumber, locationTableSortBy, locationTableSortDir, debouncedLocationSearchTerm]);

    return (
        <UserAccess perform={["Risk:View"]}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />
                    <h1>Manage my Risk</h1>

                    <ShowHide
                        evaluator={riskEnabled && riskStarted}
                        show={(
                            <Fragment>
                                <RiskSelector disabled={changingPeriod} onLoaded={onSearchLoad} />

                                <DataDisplayTable
                                    data={periodInfo}
                                    displayInRows={false}
                                    loading={loading}
                                    columnsPerRow={periodInfo.length}
                                />

                                <div className="inline-items inline-items-space align-items-stretch inline-items-fill spacer-bottom">

                                    {riskAreas.map(function (area, areaIndex) {
                                        return (
                                            <div className="inline-item panel" key={`panel-${HelperFunctions.kebabCase(area.areaName)}`}>
                                                <div className="spacer-bottom-small">
                                                    <div className="pull-left">
                                                        <h2>{area.areaName}</h2>
                                                    </div>
                                                    <ShowHide
                                                        evaluator={area.entitiesAnalysed > 0}
                                                        show={(
                                                            <div className="button-row pull-right">
                                                                <Tooltip content="Scatter Chart">
                                                                    <Button loading={false} minimal large={false} intent={!area.showPieChart ? "primary" : ""} icon="graph" onClick={() => onToggleChart(areaIndex)} />
                                                                </Tooltip>
                                                                <Tooltip content="Pie Chart">
                                                                    <Button loading={false} minimal large={false} intent={area.showPieChart ? "primary" : ""} icon="doughnut-chart" onClick={() => onToggleChart(areaIndex)} />
                                                                </Tooltip>
                                                            </div>
                                                        )}
                                                    />
                                                    
                                                    <div className="clear-divide"></div>
                                                </div>

                                                <ShowHide
                                                    evaluator={area.entitiesAnalysed > 0}
                                                    show={(
                                                        <Fragment>
                                                            <div className="inline-items spacer-bottom">
                                                                <div className="inline-item">
                                                                    <StatusBlock background={area.score?.bandColour}>{area.score?.total} - {area.score?.band}</StatusBlock>
                                                                </div>
                                                                <TrendStatus trend={area.score?.trend} />
                                                            </div>
                                                            <ShowHide
                                                                evaluator={area.showPieChart}
                                                                show={(
                                                                    <div>
                                                                        <ReactECharts
                                                                            style={{ height: '350px', width: '400px' }}
                                                                            option={{
                                                                                legend: {
                                                                                    data: []
                                                                                },
                                                                                tooltip: {},
                                                                                series: [
                                                                                    {
                                                                                        type: 'pie',
                                                                                        radius: [60, 120],
                                                                                        center: ['50%', '50%'],
                                                                                        data: area.chart.pieData,
                                                                                        label: {
                                                                                            alignTo: 'edge',
                                                                                            edgeDistance: '0%',
                                                                                            minMargin: 15,
                                                                                            formatter: function (value) {
                                                                                                return value.name.split(' ').join('\n');
                                                                                            },
                                                                                            fontSize: 12,
                                                                                            lineHeight: 14,
                                                                                            color: '#666'
                                                                                        }
                                                                                    }
                                                                                ],
                                                                                
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                                hide={(
                                                                    <ReactECharts
                                                                        style={{ height: '350px', width: '400px' }}
                                                                        option={{
                                                                            color: [
                                                                                '#cccccc',
                                                                                '#4198d7'
                                                                            ],
                                                                            radar: {
                                                                               
                                                                                radius: '70%',
                                                                                indicator: area.chart.spiderLegend,
                                                                                axisName: {
                                                                                    formatter: function (value) {
                                                                                        return value.split(' ').join('\n');
                                                                                    },
                                                                                    fontSize: 12,
                                                                                    lineHeight: 14,
                                                                                    color: '#666'
                                                                                }
                                                                            },
                                                                            legend: {
                                                                                data: []
                                                                            },
                                                                            tooltip: {},
                                                                            series: [
                                                                                {
                                                                                    type: 'radar',
                                                                                    data: area.chart.spiderData
                                                                                }
                                                                            ]
                                                                        }}
                                                                    />
                                                                )}
                                                            />

                                                            <div>
                                                                <LinkButton text={`View ${area.areaName} Breakdown`} intent="primary" href={`/risk/${area.areaName.toLowerCase()}`} disabled={changingPeriod} />
                                                            </div>

                                                        </Fragment>
                                                    )}
                                                    hide={(
                                                        <div>
                                                            <NonIdealState title="No score available for this period" icon="outdated" className="non-ideal-center">
                                                                <p>No events were raised for this risk area during this period so an overall score could not be calculated.</p>
                                                                <UserAccess perform={["Risk:Manage"]}
                                                                    yes={() => (
                                                                        <Fragment>
                                                                            <p>If you were expecting to see results for this period, please make sure you have configured your events correctly in the <InternalLink link={`/risk-settings/events/${area.areaName.toLowerCase()}`}>settings screen</InternalLink>.</p>
                                                                        </Fragment>
                                                                    )}
                                                                />
                                                            </NonIdealState>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        );
                                    })}

                                </div>

                                <ShowHide
                                    evaluator={displayGraph}
                                    show={(
                                        <div className="spacer-bottom">

                                            <h3>Risk Over Time</h3>

                                            <div className="spacer-bottom">
                                                <Tabs tabs={trendGraphs} onClick={onTrendClick} loading={loading} />
                                            </div>

                                            <div className="inline-item spacer-bottom-small">
                                                <FormSwitch checked={showGraphBands} loading={loading} alignment={Alignment.RIGHT} inline label={"Show risk bands"} onChange={onShowGraphRiskBandsChange} />
                                            </div>

                                            <ShowHide
                                                evaluator={selectedGraph != null}
                                                show={(
                                                    <ReactECharts
                                                        style={{ width: '100%' }}
                                                        option={{
                                                            color: ['#0C2235', '#4198d7'],
                                                            tooltip: {
                                                                trigger: 'axis',
                                                                formatter: displayGraphLegend,
                                                                axisPointer: {
                                                                    // Use axis to trigger tooltip
                                                                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                                                }
                                                            },
                                                            grid: {
                                                                left: '0',
                                                                right: '0',
                                                                bottom: '5%',
                                                                containLabel: true
                                                            },
                                                            xAxis: {
                                                                name: 'Period',
                                                                type: 'category',
                                                                data: selectedGraph?.graphPeriods
                                                            },
                                                            yAxis: {
                                                                type: 'value'
                                                            },
                                                            legend: {},
                                                            series: [
                                                                {
                                                                    name: 'Selected Locations Score',
                                                                    type: 'line',
                                                                    data: selectedGraph?.trendLines[0],
                                                                    markArea: {
                                                                        silent: true,
                                                                        itemStyle: {
                                                                            opacity: 0.3,
                                                                        },
                                                                        label: {
                                                                            visible: true,
                                                                            position: ['100%', '50%'],
                                                                            align: 'right',
                                                                            offset: [-10, -4]
                                                                        },
                                                                        symbol: 'none',
                                                                        data: showGraphBands ? graphBands : []
                                                                    }
                                                                },
                                                                {
                                                                    name: 'Overall Company Score',
                                                                    type: 'line',
                                                                    lineStyle: {
                                                                        normal: {
                                                                            type: 'dashed'
                                                                        }
                                                                    },
                                                                    data: selectedGraph?.trendLines[1]
                                                                }
                                                            ]
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}
                                />

                                <h3>Risk Score by Location</h3>

                                <div className="pull-left">
                                    <FormTextInput placeholder="Location Search" onChange={onSearchChange} value={locationSearchTerm} large icon="search" id="location-search-field" />
                                </div>

                                <ListingTable
                                    id="listing-table-locations"
                                    headers={locationTableHeaders}
                                    data={locationTableData}
                                    totalRecordCount={locationTableRecordCount}
                                    onPagingChange={onLocationPagingChange}
                                    loadingData={loading || loadingLocationList}
                                    noDataMessage={"Please change your search criteria"}
                                    sortable
                                    sortableHeaders={locationSortableHeaders}
                                    sortedBy={locationTableSortBy}
                                    sortedDir={locationTableSortDir}
                                    onSort={onLocationTableSort}
                                />

                            </Fragment>
                        )}
                        hide={(
                            <div>
                                <ShowHide
                                    evaluator={riskEnabled}
                                    show={(
                                        <NonIdealState title="Your first risk period is currently in progress" icon="outdated" className="wide">
                                            <p>The risk feature has been switched on for your account and the first risk period is either waiting to begin or is in progress. Once the period has completed on the <DateToLocal format="Do MMMM YYYY">{nextPeriodStartDate}</DateToLocal> then the results will be shown on this page for those locations that you have access to.</p>
                                        </NonIdealState>
                                    )}
                                    hide={(
                                        <NonIdealState title="Risk has not been switched on for your account" icon="warning-sign" className="wide">
                                            <UserAccess perform={["Risk:Manage"]}
                                                yes={() => (
                                                    <Fragment>
                                                        <p>Risk in Vision has been enabled for your account but not switched on.</p>
                                                        <p>To get the process started you will need to go to the <InternalLink link={`/risk-settings`}>settings page</InternalLink> and complete your risk setup.</p>
                                                        <p>Risk is broken down into 3 sections; <strong>Employee</strong>, <strong>Asset</strong> and <strong>Organisation</strong>. Each area has an overall score, this is broken down into individual results for locations and subjects.</p>
                                                        <p>Within each risk area there are events that contribute towards the risk score. You can enable/disable or change the weighting of these events to create a more tailored scoring system for your organisation. If you don't want to go to that level of customisation, you can use the default weightings.</p>
                                                        <p>Everything that has a risk score is also associated to a banding system which groups collective scores into a friendly grading system to easily identify problem areas. You can edit the default banding levels to something that is more appropriate for your company.</p>
                                                        <p>Once risk is enabled, the results for your risk scores will be calculated from the start date you have chosen and will display on this page once the first 28 day period is completed.</p>
                                                    </Fragment>
                                                )}
                                                no={() => (
                                                    <Fragment>
                                                        <p>Risk has been turned off on your account so scores will not be calculated and shown on this page. Please contact your company admin about switching on this feature.</p>
                                                    </Fragment>
                                                )}
                                            />
                                        </NonIdealState>
                                    )}
                                />
                            </div>
                        )}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );

}