import React, { useState } from 'react';
import { AuditingTable } from "components/auditing";
import { JobService } from "services";
import { NotificationToaster } from "components/notifications";
import { Intent } from '@blueprintjs/core';

export function JobChangelog(props) {

    const noDataMessage = "It looks like no previous changes have been made to your job.";
    const [tableData, setTableData] = useState(null);
    const [tableRecordCount, setTableRecordCount] = useState(0);

    function onTableChange(pageSize, pageNumber, startDate, endDate) {
        JobService.getChangelog(props.jobId, pageSize, pageNumber, startDate, endDate).then(r => {
            setTableData(r.data);
            setTableRecordCount(r.totalCount);
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, error);
        });
    }

    return (
        <AuditingTable data={tableData} totalCount={tableRecordCount} onChange={onTableChange} noDataMessage={noDataMessage} pageSize={100}></AuditingTable>
    );
}