
const initialState = {
    users: [],
    assets: [],
    assignDrivers: [],
    scheduleWizard: [],
    locations: [],
    schedules: [],
    scheduledReports: [],
    migration: [],
    reviews: [],
    jobAmendments: [],
    jobs: [],
    completedJobs: [],
    reviewDates: {
        startDate: undefined,
        endDate: undefined
    },
    reviewLocationHierarchy: {
        list: [],
        hierarchySelected: undefined
    },
    auditingDates: {
        startDate: undefined,
        endDate: undefined
    },
    walkaroundChecks: [],
    walkaroundDates: {
        startDate: null,
        endDate: null
    },
    alerts: [],
    jobListingDates: {
        startDate: null,
        endDate: null
    },
    jobListingLocation: {
        list: []
    },
    jobSearch: null,
    collisions: [],
    externalContacts: [],
    externalContactListingLocation: {
        list: null
    },
    riskSearch: {}
}

const FilteringReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_FILTER":
            var currentState = {
                ...state
            };

            currentState[action.name] = action.payload
            return currentState;
        default:
            return state;
    }
}

export default FilteringReducer;