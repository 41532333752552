import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { ShowHide } from 'components/layout/ShowHide';
import { FormHelperText } from "components/form-fields";
import 'components/listing/Listing.css';

export function Table(props) {

    return (
        <Fragment>
            <table className={classNames('bp3-html-table', 'bp3-html-table-striped', 'listing-table', { "nowrap-table": props.noWrap })}
                style={{ width: props.fitToContent ? "initial" : "100%" }}>
                <thead>
                    <ShowHide
                        evaluator={!!props.headerData?.topLevelHeaders?.length}
                        show={
                            <tr className='listing-table-top-level-header'>
                                {props.headerData?.topLevelHeaders?.map((header, index) => {
                                    return (
                                        <th
                                            key={header.key}
                                            colSpan={header.colspan ? header.colspan : 1}
                                            className={classNames({ "table-header-small": props.shrinkLastColumn && index === props.headerData.headers.length - 1 })}
                                        >
                                            {header.value}
                                        </th>
                                    );
                                })}
                            </tr>
                        }
                    />
                    <tr>
                        {props.headerData.headers.map((header, index) => {
                            let columnClass = props.columnClasses[index] ?? null;
                            return (
                                <th key={header.key} className={classNames({ "table-header-small": props.shrinkLastColumn && index === props.headerData.headers.length - 1 }, columnClass)}>
                                    {header.value}
                                </th>
                            );
                        })}

                        {props.headerData.additionalHeaders}
                    </tr>
                </thead>

                <tbody>
                    <Fragment>
                        {
                            props.data.map((row) => {
                                return <tr key={row.key} className={row.class} onClick={row.onRowClick}>
                                    {row.cells.map((cell, colIndex) => {
                                        let columnClass = props.columnClasses[colIndex] ?? null;
                                        let spanClass = props.loadingData ? props.loadingClass : null;
                                        return <td key={colIndex} className={columnClass}><span className={classNames(
                                            spanClass
                                        )}>{cell}</span></td>;
                                    })}
                                    {row.additionalCells}
                                </tr>;
                            })
                        }
                    </Fragment>
                </tbody>
            </table>
            <FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
        </ Fragment>
    );
}

Table.defaultProps = {
    data: [],
    headerData: {},
    loadingData: false,
    fitToContent: false,
    dangerHelperText: null,
    shrinkLastColumn: false,
    noWrap: false,
    columnClasses : [],
    loadingClass: "bp3-skeleton"
};

Table.propTypes = {
    data: PropTypes.array.isRequired,
    headerData: PropTypes.object.isRequired,
    loadingData: PropTypes.bool,
    fitToContent: PropTypes.bool,
    dangerHelperText: PropTypes.node,
    shrinkLastColumn: PropTypes.bool,
    noWrap: PropTypes.bool,
    columnClasses : PropTypes.array,
    loadingClass: PropTypes.string
};
