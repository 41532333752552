import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { FormDatePeriodSelector, FormLocationSelector, FormTextInput } from "components/form-fields"
import { Tooltip } from "components/tooltip";
import { Button, LinkButton } from "components/buttons"
import { Filter } from "components/filtering";
import { NotificationToaster, NotificationInline } from "components/notifications";
import { Intent, Position } from "@blueprintjs/core";
import { ListingTable } from "components/listing";
import { AssetService, LinkService } from 'services';
import { KeyBlock } from "components/key/KeyBlock";
import { Numeric } from "components/formatting/Numeric";
import { DateToLocal } from "components/dates/DateToLocal";
import { PreferencesModal } from "components/preferences-modal/PreferencesModal";
import classNames from "classnames";
import moment from "moment";
import "./Fleet.css"
import { KeyModal } from "./key-modal";
import { DayDetailModal } from "./day-detail-modal";
import { ReportList } from "components/reporting/ReportList";


export function Fleet(props) {
    const requiredActions = ["Fleet:View"];
    const canAccessPage = UserHasAccess(requiredActions);
    const firstColumnLabel = "ID/Registration";
    const calculatedColumnNames = ["KM without card"];
    let tableHeadersPrefix = [firstColumnLabel, ""];
    const initialDate = moment().startOf('isoWeek');
    const assetTypesMappings = [
        { "id": 1, "img": "hgv-scaled.svg", "name": "Heavy Goods Vehicle" },
        { "id": 2, "img": "lcv-scaled.svg", "name": "Light Commercial Vehicle" },
        { "id": 3, "img": "psv-scaled.svg", "name": "Public Service Vehicle" },
        { "id": 4, "img": "trailer-scaled.svg", "name": "Trailer" },
        { "id": 5, "img": "dst-scaled.svg", "name": "Dangerous Substances Tanker" },
        { "id": 6, "img": "crane-scaled.svg", "name": "Crane" },
        { "id": 7, "img": "tail lift-scaled.svg", "name": "Tail Lift" },
        { "id": 8, "img": "lift truck-scaled.svg", "name": "Lift Truck" },
        { "id": 9, "img": "winch-scaled.svg", "name": "Winch" },
        { "id": 10, "img": "hgv-scaled.svg", "name": "Vehicle" },
        { "id": 12, "img": "van-scaled.svg", "name": "Van" },
        { "id": 13, "img": "car-scaled.svg", "name": "Car" },
        { "id": 14, "img": "lifting equipment-scaled.svg", "name": "Lifting Equipment" },
        { "id": 15, "img": "trailer-scaled.svg", "name": "Light Trailer" }
    ];

    const statusAttributeMappings = [
        { "Id": 1, "Colour": "00C6B2" },
        { "Id": 2, "Colour": "EC2D6D" },
        { "Id": 3, "Colour": "FF7454" }
    ];

    const ownershipAttributeMappings = [
        { "id": 1, "name": "Owned", "className": "", "colour": "00c6b2" },
        { "id": 2, "name": "Hired", "className": "hollow", "colour": "00c6b2" },
        { "id": 3, "name": "Loaned", "className": "halfed", "colour": "00c6b2" },
        { "id": 4, "name": "Leased", "className": "radio", "colour": "00c6b2", "icon": "radio" },
        { "id": 5, "name": "Sold", "className": "", "colour": "ec2d6d", "icon": "tag" },
        { "id": 6, "name": "Returned to lender", "className": "", "colour": "ec2d6d", "icon": "chevron-left" },
    ];

    const periodSelectorDateFormat = "DD MMM YYYY";
    const defaultItemsPerPage = 25;
    const daysInWeek = 7;
    const saturdayInt = 6;
    const sundayInt = 7;
    const weekendDays = [saturdayInt, sundayInt];

    const [isInitialising, setIsInitialising] = useState(true);
    const [isSearching, setIsSearching] = useState(true);
    const [locationsLoading, setLocationsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [useHierarchy, setUseHierarchy] = useState(true);
    const [loadedFilters, setLoadedFilters] = useState(false);
    const inputSearchRef = useRef(null);
    const [startDate, setStartDate] = useState(initialDate);
    const [searchResultData, setSearchResultData] = useState([]);

    const [lastSearchPayload, setLastSearchPayload] = useState(null);
    const [loadedAssetIds, setLoadedAssetIds] = useState([]);

    // Listings objects:
    const [searchResult, setSearchResult] = useState([]);
    const [activeHeaders, setActiveHeaders] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortedBy, setSortedBy] = useState(firstColumnLabel);
    const [sortedDir, setSortedDir] = useState("A");
    const [sortableHeaders, setSortableHeaders] = useState([firstColumnLabel]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [perPage, setPerPage] = useState(defaultItemsPerPage);
    const [columnClasses, setColumnClasses] = useState([]);

    // Key Modal
    const [showKeyModal, setShowKeyModal] = useState(false);
    const [ownershipForKeyModal, setOwnershipForKeyModal] = useState([])
    const [assetStatusesForKeyModal, setAssetStatusesForKeyModal] = useState([])
    const [vehicleActivitiesForKeyModal, setVehicleActivitiesForKeyModal] = useState([])

    // Report Downloading
    const [reportDownloading, setReportDownloading] = useState(false);

    // DisplayPreferencesModal
    const [displayPreferences, setDisplayPreferences] = useState([]);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);
    const [tachoWeeksToShow, setTachoWeeksToShow] = useState(4);
    const [preferencesUpdated, setPreferencesUpdated] = useState(false);

    // Day Detail Modal
    const [showDayDetailModal, setShowDayDetailModal] = useState(false);
    const [dayDetailModalAssetId, setDayDetailModalAssetId] = useState(0);
    const [dayDetailModalDate, setDayDetailModalDate] = useState("");

    const handleSavePreferences = (newPreferences, newTachoWeeksToShow) => {
        setDisplayPreferences(newPreferences);
        setTachoWeeksToShow(newTachoWeeksToShow);
        setPreferencesUpdated(true);
    };

    const locationListOnFilterOpen = useRef(selectedLocations);

    //Initialisation:

    function formatOwnerships(assetOwnerships) {
        return assetOwnerships.map((x) => {
            const mappingItem = ownershipAttributeMappings.find(y => y.id === x.id);
            return { "name": x.name, "colours": `#${mappingItem.colour}`, "id": x.id, "className": mappingItem.className, "icon": mappingItem.icon }
        });
    }

    function formatStatuses(assetStatuses) {
        return assetStatuses.map((x) => {
            const mappingItem = statusAttributeMappings.find(y => y.Id === x.id);
            return { "name": x.name, "colours": `#${mappingItem.Colour}`, "id": x.id }
        });
    }

    function formatPageFilters(assetStatuses, assetOwnerships, assetTypes) {
        return [{
            "name": "Status",
            "displayName": "Status",
            "items": assetStatuses
        },
        {
            "name": "Ownership",
            "displayName": "Ownership",
            "items": assetOwnerships
        },
        {
            "name": "AssetType",
            "displayName": "Type",
            "items": assetTypes
        }]
    }

    function callAssetOptions() {
        AssetService.getAssetOptions().then((response) => {
            if (response != null) {
                setPageFilters(formatPageFilters(response.assetStatuses, response.assetOwnerships, response.assetTypes));
                setOwnershipForKeyModal(formatOwnerships(response.assetOwnerships));
                setAssetStatusesForKeyModal(formatStatuses(response.assetStatuses));
            }
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Asset Options. ${error}`);
        });
    }

    function callActivityTypes() {
        AssetService.getActivityTypes().then((response) => {
            if (response != null) {
                const activityTypes = response.map((x) => {
                    return { "name": x.name, "colours": x.colour, "id": x.id }
                });
                setVehicleActivitiesForKeyModal(activityTypes);
            }
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Activity Types. ${error}`);
        });
    }

    function callTachoPreferences() {
        AssetService.getTachoPreferences().then((response) => {
            setDisplayPreferences(response.displayOptions);
            setTachoWeeksToShow(response.preferences.tachoWeeksToShow);

        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get user tacho preferences. ${error}`);
        });
    }

    useEffect(() => {
        recalculateHeaders();
    }, [displayPreferences, tachoWeeksToShow]);

    useEffect(() => {
        if (!canAccessPage) {
            return;
        }

        dispatch({ type: 'SITE_FULL_WIDTH' });
        callAssetOptions();
        callActivityTypes();
        callTachoPreferences();
        setLoadedFilters(true);
        setIsInitialising(false);
    }, []);

    useEffect(() => {
        if (preferencesUpdated) {
            performSearch();
            setPreferencesUpdated(false);
        }
    }, [preferencesUpdated]);


    //FILTERS:
    const defaultFilters = [{ name: "Status", value: "Active", displayName: "Active" }]
    let selectedFilters = useSelector(state => state.filters["assets"]);
    const dispatch = useDispatch();
    if (selectedFilters.length === 0) {
        selectedFilters = defaultFilters;
    }
    dispatch(SetFiltering(props.filterName, selectedFilters));

    function onSelectedLocationsChange(locations) {
        setLocationsLoading(false);
        setSelectedLocations(locations);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setAppliedFilters(listingFilters);
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    //Headers Logic/Formatting:
    function recalculateHeaders() {
        //When start date changes, update the calendar columns:
        let calendarHeaders = [];
        for (let i = 0; i < (daysInWeek * tachoWeeksToShow); i++) {
            let thisDay = moment(startDate).add(i, 'days');
            let thisClassNames = "";
            if (weekendDays.includes(thisDay.isoWeekday())) {
                thisClassNames += "weekend";
            }
            calendarHeaders.push(<div key={`cal${thisDay.format("DD")}`} className={classNames(thisClassNames)}>{thisDay.format("ddd")}<br />{thisDay.format("DD")}</div>);
        };

        let formattedAdditionalHeaders = displayPreferences.filter(x => x.userSelected).sort((x, y) => x.userPriority - y.userPriority).map((x) => {
            return <div key={`add${x.userPriority}`} className="additional-column" style={{ width: x.minWidth }}>{x.name}</div>;
        });
        
        let additionalHeaderKeys = formattedAdditionalHeaders.map((x) => { return x.key.toString() });

        //map the additional columns
        setActiveHeaders([...tableHeadersPrefix, ...calendarHeaders, ...formattedAdditionalHeaders]);
        setSortableHeaders([...tableHeadersPrefix, ...additionalHeaderKeys]);
        setColumnClasses([...Array(tableHeadersPrefix.length).fill("base-column"), ...Array(calendarHeaders.length).fill("calendar-day"), ...Array(formattedAdditionalHeaders.length).fill("")]);
    }

    useEffect(() => {
        if (isInitialising || locationsLoading) { return; }

        recalculateHeaders();
    }, [startDate]);


    function getIdField(assetId, registrationNumber) {
        return (
            <a href={LinkService.getVehicleProfileUrl(assetId)}>
                <span className="registration">{registrationNumber}</span>
            </a>
        );
    }
    //Columns Logic/Formatting:
    function getVehicleIcon(assetTypeId) {
        let img = "unknown-vehicle-scaled.svg";
        let vehicleType = "unknown";

        var assetType = assetTypesMappings.find(o => {
            return o.id === assetTypeId
        });

        if (assetType) {
            img = assetType.img;
            vehicleType = assetType.name;
        }
        return (
            <div className="vehicle-icon">
                <Tooltip position={Position.TOP} content={vehicleType}>
                    <img src={`/icons/vehicles/${img}`} alt={vehicleType} className="vehicle-icon" />
                </Tooltip>
            </div>
        );
    }

    function getStatusIcon(status, ownership) {
        let statusAttribute = statusAttributeMappings.find(o => {
            return o.Id === status.id
        });

        let ownershipAttributes = ownershipAttributeMappings.find(o => {
            return o.id === ownership.id
        });

        let className = "";
        let iconName = "";
        let statusColour = "FF7454";
        if (ownershipAttributes) {
            className = ownershipAttributes.className ?? "";
            iconName = ownershipAttributes.icon ?? "";
        }
        if (statusAttribute) {
            statusColour = statusAttribute.Colour ?? "FF7454";
        }
        return (
            <Tooltip content={`${status.name ?? ''} ${ownership.name ?? ''}`} >
                <KeyBlock rounded={true} icon={iconName} className={className} colours={`#${statusColour}`} size={8} />
            </Tooltip>
        );
    }

    function getCalendarBlockPopoverMessage(activityDescriptions) {
        return (
            <div>
                <p>The data for today is:</p>
                <ul>
                    {activityDescriptions.map((x) => {
                        return <li key={x}>{x}</li>
                    })}
                </ul>
            </div>
        );
    }

    function displayDayDetailModal(assetId, date) {
        setDayDetailModalAssetId(assetId);
        setDayDetailModalDate(date);
        setShowDayDetailModal(true);
    }

    function getCalendarBlock(activityTypes, isAwaitingData, assetId, date) {
        let toolTipDisabled = false;
        if (!activityTypes || activityTypes.length === 0) {
            activityTypes = [{ name: "", colours: '#FFFFFF' }];
            toolTipDisabled = true;
        }
        let formattedDate = date.format("YYYY-MM-DD")
        return (<Tooltip content={getCalendarBlockPopoverMessage(activityTypes.map(x => x.name))} disabled={toolTipDisabled} >
            <KeyBlock colours={activityTypes.map(x => x.colours)} onClick={() => displayDayDetailModal(assetId, formattedDate)} size={14} loading={isAwaitingData} selectable />
        </Tooltip>
        );
    }

    function getCalendarRowsItems(calendarItems, assetId) {
        let isAwaitingData = calendarItems === null;
        if (!calendarItems) {
            calendarItems = Array(daysInWeek * tachoWeeksToShow).fill([]);
        }

        let rows = [];
        for (let i = 0; i < daysInWeek * tachoWeeksToShow; i++) {
            let activities = [];
            if (calendarItems[i] !== undefined) {
                activities = calendarItems[i].map(x => {
                    return vehicleActivitiesForKeyModal.find(y => y.id === x);
                });
            }
            rows.push(getCalendarBlock(activities, isAwaitingData, assetId, moment(startDate).add(i, 'd')));
        }
        return rows;
    }

    function formatColumnDataFromSearchResult(apiData) {
        return apiData.map((x) => {
            return {
                key: x.assetId,
                status: x.assetStatus,
                ownership: x.assetOwnership,
                assetType: x.assetType,
                registrationNumber: x.registrationNumber,
                additionalColumns: x.columns,
                calendarItems: null
            }
        });
    }

    function mapColumnRows(row) {
        let baseColumns = [
            <div className="nowrap inline-items no-spacing" key={`statusIcon_${row.key}`}>
                {getStatusIcon(row.status, row.ownership)}
                {getIdField(row.key, row.registrationNumber)}
            </div>,
            <div className="nowrap inline-items no-spacing" key={`Links_${row.key}`}>
                {getVehicleIcon(row.assetType.id)}
                {LinkService.renderAssetCalendarLink(row.key, startDate)}
            </div>, ...getCalendarRowsItems(row.calendarItems, row.key)
        ];
        let additionalRows = row.additionalColumns.map(x => {
            if (!displayPreferences) {
                return x.value;
            }
            let preferenceItem = displayPreferences.find(y => y.name === x.name);
            let format = "none";
            if (preferenceItem) {
                format = preferenceItem.format;
            }

            switch (format) {
                case "DateTime":
                    return <div className="additional-column"><DateToLocal format="DD/MM/YYYY" invalidDateString="">{x.value}</DateToLocal></div>;
                case "Int":
                    return <div className="additional-column"><Numeric>{x.value}</Numeric></div>;
                default:
                    return <div className="additional-column">{x.value}</div>;
            }
        });
        return baseColumns.concat(additionalRows);
    }

    //Search logic:
    function getSearchPayload() {
        let filters = appliedFilters.map(x => {
            return { key: x.name, value: x.value }
        });

        if (selectedLocations != null) {
            selectedLocations.forEach(function (l) {
                filters = [...filters,
                { key: "Location", value: l }
                ];
            });
        }

        return {
            requestCount: perPage,
            pageNumber: currentPageNumber,
            searchTerm: searchTerm,
            sortField: sortedBy === firstColumnLabel ? "AssetIdentifier" : sortedBy,
            sortDirection: sortedDir,
            filters: filters,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: moment(startDate).add((daysInWeek * tachoWeeksToShow) - 1, 'days').format("YYYY-MM-DD")
        };
    }

    useEffect(() => {
        if (searchResultData !== undefined) {
            const data = searchResultData.map((d) => {
                return [...mapColumnRows(d)]
            });
            setSearchResult(data);
        }
    }, [searchResultData]);

    function performSearch() {
        setIsSearching(true);
        const searchPayload = getSearchPayload();
        AssetService.searchAssetsWithPreferences(searchPayload).then((response) => {

            if (response.totalCount === 0) {
                setSearchResultData([]);
                setSearchResult([]);
                setTotalRecords(response.totalCount);
                setIsSearching(false);
                return;
            }

            if (response.data != null) {
                setSearchResultData(formatColumnDataFromSearchResult(response.data));
            }
            setTotalRecords(response.totalCount);
            setLastSearchPayload(searchPayload);
            setLoadedAssetIds(response.data.map(x => x.assetId));
            setIsSearching(false);
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Asset search failed. Please try again. ${error} `);
            setIsSearching(false);
        });
    }

    function AddTachoDataToSearchResultData(prefSearchResultData, tachoResultData) {
        prefSearchResultData.forEach((x) => {
            let calendarItems = tachoResultData.find(y => y.assetId === x.key);
            x.calendarItems = calendarItems ? calendarItems.activity : null;
        });
        return prefSearchResultData;
    }

    function refreshCalendarData(response) {
        setSearchResultData((srd) => {
            let newSrd = [...srd];
            return AddTachoDataToSearchResultData(newSrd, response);
        });
    }

    function doCalendarRefresh() {
        if (loadedAssetIds.length === 0) { return; }

        refreshCalendarData([]);
        AssetService.searchTachoDataWithAssetIds(loadedAssetIds, startDate.format("YYYY-MM-DD"), tachoWeeksToShow).then((response) => {
            refreshCalendarData(response);
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to read Tacho data. Calendar may not be populated correctly. Please refresh page and try again. ${error} `);
        });
        setIsSearching(false);
    }

    useEffect(() => {
        doCalendarRefresh();
    }, [loadedAssetIds]);

    useEffect(() => {
        if (displayPreferences.filter(x => x.userSelected && calculatedColumnNames.includes(x.name)).length > 0) {
            //If the user has any calculated columns displayed, we need to update them as well as the calendar items:
            performSearch();
        }
        else {
            doCalendarRefresh();
        }
    }, [startDate, tachoWeeksToShow]);

    useEffect(() => {
        if (isInitialising || locationsLoading) { return; }
        performSearch();

    }, [searchTerm, appliedFilters, selectedLocations, sortedBy, sortedDir, perPage, currentPageNumber])

    function onSearchTextChange(item) {
        setSearchTerm(item);
    }

    function openKeyModal() {
        setShowKeyModal(true);
    }

    function closeKeyModal() {
        setShowKeyModal(false);
    }

    function onWeekChange(periodStart) {
        setIsSearching(true);
        setStartDate(moment(periodStart, periodSelectorDateFormat));
    }

    function onPagingChange(page, itemsPerPage) {
        setCurrentPageNumber(page);
        setPerPage(itemsPerPage);
    }

    function onTableSort(header, direction) {
        setSortedBy((typeof header === 'string') ? header : header.props.children);
        setSortedDir(direction);
    }
    function openPreferencesModal() {
        setShowPreferencesModal(true);
    }

    function closePreferencesModal() {
        setShowPreferencesModal(false);
    }

    function onReportDownloading(isDownloading) {
        setReportDownloading(isDownloading);
    }

    return (
        <UserAccess perform={requiredActions}
            yes={() => (
                <div className="row">
                    <h1>Manage My Fleet</h1>

                    <div className={classNames({ "spacer-bottom": !showFilters })}>
                        <div className="inline-items">
                            <FormLocationSelector
                                businessArea={"Tacho"}
                                loading={isInitialising}
                                onLocationListOpened={onLocationListOpened}
                                selectedLocations={selectedLocations}
                                setSelectedLocations={onSelectedLocationsChange}
                                useLocationDefaults={true}
                                useHierarchy={useHierarchy}
                                setUseHierarchy={setUseHierarchy}
                                locationDefaultsToTrue={loadedFilters ? true : null}
                            />
                            <FormTextInput inputRef={inputSearchRef} placeholder="Search Fleet" onChange={(x) => onSearchTextChange(x.target.value)} value={searchTerm} large disabled={isInitialising} icon="search" id="user-search-field" />
                            <Tooltip content="Filter" position="right">
                                <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                            </Tooltip>
                        </div>
                    </div>

                    <Filter filterName="assets" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} defaultFilterValues={[{ name: 'Status', displayName: 'Active', value: 1 }, { name: 'Status', displayName: 'Vehicle Off Road', value: 3 }]} />

                    <div className="button-row button-row-stacked-mobile spacer-bottom">
                        <LinkButton intent="primary" text="Add Asset" href="/asset/create/business-area/1" id="asset-create" />
                        <ReportList lastSearchPayload={lastSearchPayload} onReportDownloadingChange={onReportDownloading} pageName='Fleet' />
                        <Button intent="secondary" text="Key" id="key" onClick={openKeyModal} className="key-button" />
                    </div>
                    <NotificationInline intent="success" text="Your report has been queued, and will download as soon as it's ready." show={reportDownloading} />

                    <KeyModal
                        showKeyModal={showKeyModal}
                        closeKeyModal={closeKeyModal}
                        isInitialising={isInitialising}
                        ownershipForKeyModal={ownershipForKeyModal}
                        assetStatusesForKeyModal={assetStatusesForKeyModal}
                        vehicleActivitiesForKeyModal={vehicleActivitiesForKeyModal}
                    />

                    <DayDetailModal
                        showDayDetailModal={showDayDetailModal}
                        closeDayDetailModal={() => setShowDayDetailModal(false)}
                        assetId={dayDetailModalAssetId}
                        date={dayDetailModalDate.toString()}
                        statusAttributeMappings={assetStatusesForKeyModal}
                        ownershipAttributeMappings={ownershipForKeyModal}
                        lastSearchPayload={lastSearchPayload}
                        vehicleActivityTypes={vehicleActivitiesForKeyModal}
                    />

                    <div className="inline-items pull-left">
                        <FormDatePeriodSelector
                            id="listing-period"
                            periodType="weeks"
                            periodsToShow={tachoWeeksToShow}
                            startDate={initialDate.toString()}
                            onChange={onWeekChange}
                            dateFormat={periodSelectorDateFormat}
                            showCalendar={true}
                            showText={false}
                        />
                        <div className="form-field">
                            <Button text="Configure Preferences" minimal={true} large={false} intent="secondary" icon="cog" id="configure-properties" className="configure-prefs-button" onClick={openPreferencesModal} />
                        </div>
                        <PreferencesModal
                            isOpen={showPreferencesModal}
                            onClose={closePreferencesModal}
                            displayPreferences={displayPreferences}
                            numberOfWeeks={tachoWeeksToShow}
                            cancelButtonIntent={Intent.NONE}
                            onSave={handleSavePreferences}
                            onPreferencesSaved={() => setPreferencesUpdated(true)}
                            onLoadPreferences={AssetService.getTachoPreferences}
                            onSavePreferences={AssetService.saveTachoPreferences}
                            preferencesKey={"Tacho"}
                        />
                    </div>
                    <div className="listing-table-wrapper">
                        <ListingTable
                            id="listing-table-fleet"
                            headers={activeHeaders}
                            data={searchResult}
                            totalRecordCount={totalRecords}
                            onPagingChange={onPagingChange}
                            loadingData={isSearching}
                            noDataHeading="No Assets Found"
                            noDataMessage="No assets for this search criteria"
                            sortable
                            sortableHeaders={sortableHeaders}
                            sortedBy={sortedBy}
                            sortedDir={sortedDir}
                            onSort={onTableSort}
                            allowTableScroll={true}
                            columnClasses={columnClasses}
                            loadingClass={searchResultData.length === 0 ? "bp3-skeleton" : "bp3-skeleton loading"}
                        />
                    </div>
                </div>
            )}
            no={() => <PageRestricted />}
        />
    );
}