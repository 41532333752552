import React, { useEffect, useState } from "react";
import { ReportList } from "components/reporting/ReportList";
import { NotificationInline, NotificationToaster } from "components/notifications";
import PropTypes from "prop-types";
import { Button } from "components/buttons";
import { Intent, Tabs, Tab, MenuItem } from "@blueprintjs/core";
import { AssetService, BlobStorageService } from 'services';
import { Panel } from "components/panel";
import './ActivitiesTab.css';
import { ActivitiesList, InfoPanel } from 'components/fleet';
import moment from "moment";
import { ShowHide } from "components/layout";
import { HarshBrakingPanel } from "components/fleet/HarshBrakingPanel";
import { ActivitiesGraph } from "components/fleet/ActivitiesGraph";
import { FormDatePeriodSelector } from "components/form-fields";
import { AssignDriverModal } from "pages/fleet/assign-driver-modal/AssignDriverModal";
import { UnassignDriverModal } from "pages/fleet/unassign-driver-modal/UnassignDriverModal";
import { UserHasAccess } from "components/user-access";

export function ActivitiesTab(props) {
    const summaryColor = "#008CFF";
    const harshBrakingColour = "#F0578A";
    
    const activitiesSummaryTabId = "SUMMARY";
    const periodSelectorDateFormat = "DD MMM YYYY";
    const journeyNameMaxLength = 14;

    const vehicleUnitUploaded = 1;
    const vehicleUploadedDrivingNoCard = 2;
    const vehicleActivityTakenFromAnotherSource = 3;
    const assignedJourney = 4;
    const driverAssignedToVehicleData = 5;

    const assignDriversPermission = "Fleet:AssignWithoutCard";
    const canAssignDrivers = UserHasAccess(assignDriversPermission);

    let firstUploadId;
    if (props.activitiesApiResponse && props.activitiesApiResponse.uploads && props.activitiesApiResponse.uploads.length !== 0) {
        firstUploadId = props.activitiesApiResponse.uploads[0].uploadId;
    }

    const [reportDownloading, setReportDownloading] = useState(false);
    const [uploadDownloading, setUploadingDownloading] = useState(false);
    const [displayedActivities, setDisplayedActivities] = useState([]);
    const [undisplatedActivities, setUndisplayedActivities] = useState([]);
    const [cardIwPanelData, setCardIwPanelData] = useState([]);
    const [harshBrakingPanelData, setHarshBrakingPanelData] = useState(<></>);
    const [dataSelectTabs, setDataSelectTabs] = useState([]);
    const [currentActivityTab, setCurrentActivityTab] = useState(activitiesSummaryTabId);
    const [initialDate] = useState(props.date);
    const [showAssignDriverModal, setShowAssignDriverModal] = useState(false);
    const [showUnassignModal, setShowUnassignModal] = useState(false);
    const [assignDriverModalCardIw, setAssignDriverModalCardIw] = useState(null);
    const [unassignModalCardIw, setUnassignModalCardIw] = useState(null);
    const [highlightedActivity, setHighlightedActivity] = useState(null);
    const [liveChangesToCardIws, setLiveChangesToCardIws] = useState([]);

    function onReportDownloading(isDownloading) {
        setReportDownloading(isDownloading);
    }
    
    function OnGetFilesClick() {
        setUploadingDownloading(true);
        AssetService.getDownloadLinkForVehicleUnitFile(firstUploadId).then((response) => {
            BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName);
            setUploadingDownloading(false);
        }, (error) => {
            setUploadingDownloading(false);
            NotificationToaster.show(Intent.DANGER, `Failed to get File. ${error}`, false);
        });
    }

    function getColourFromType(vehicleActivityType)
    {
        if (!vehicleActivityType) {
            return summaryColor;
        }
        return props.vehicleActivityTypes.find((t) => t.id === vehicleActivityType).colours;
    }

    function formatJourneyTypeName(journeyName) {
        if (!journeyName) {
            return "";
        }
        if (journeyName.length <= journeyNameMaxLength) {
            return journeyName;
        }
        return String(journeyName).substring(0, journeyNameMaxLength) + "...";
    }

    function getPanelTitleFromType(vehicleActivityType, journeyName)
    {
        switch (vehicleActivityType) {
            case vehicleUnitUploaded: return "Vehicle Unit Uploaded";
            case vehicleUploadedDrivingNoCard: return "Vehicle Upload - Driving, No Card Inserted";
            case vehicleActivityTakenFromAnotherSource: return "Vehicle activity taken from another source";
            case assignedJourney: return `Assigned Journey - Journey Type: ${formatJourneyTypeName(journeyName)}`;
            case driverAssignedToVehicleData: return "Driver Assigned to Vehicle Data";
            default: return "Summary";
        }
    }

    function formatHarshBraking(data)
    {
        if (data.length !== 0)
            return <HarshBrakingPanel items={data} leftColor={harshBrakingColour} />;
        else
            return <></>;
    }

    function onDayChange(day) {
        props.onDateChanged(moment(day).format("YYYY-MM-DD"));
    }

    function resetData() {
        //Set Activities pane:
        setDisplayedActivitiesData(activitiesSummaryTabId);
        setLiveChangesToCardIws([]);
    }

    function refreshData(){
        //Tabs:
        setCardIwTabs();
        //Panels:
        setCardIwPanels();
    }
    
    useEffect(() => {
        refreshData();
    }, [liveChangesToCardIws])

    useEffect(() => {
        resetData();
        refreshData();

    }, [props.assetDetails, props.date, props.activitiesApiResponse]);

    useEffect(() => {
        if (props.harshBrakingApiResponse && props.harshBrakingApiResponse.length !== 0){
            setHarshBrakingPanelData(formatHarshBraking(props.harshBrakingApiResponse));
        }
    }, [props.harshBrakingApiResponse]);

    function setDisplayedActivitiesData(id)
    {
        setCurrentActivityTab(id);
        setUndisplayedActivities([]);
        if (!id)
        {
            return;
        }
        if (String(id).startsWith("CardIw"))
        {
            let cardIw = props.activitiesApiResponse.cardIws.find((c) => c.cardIwId === parseInt(id.replace("CardIw", "")));
            setDisplayedActivities(() => {
                if (cardIw) {
                    return cardIw.activities.map((a) => {
                        return Object.assign(a, {bgColor: getColourFromType(cardIw.vehicleActivityType)});
                    });
                }
                else
                {
                    return [];
                }
            });
            //set 'undisplayed' activities, this is the grey data for the graph:
            setUndisplayedActivities(() => {
                let subsequentActivities = props.activitiesApiResponse.cardIws.filter((c) => c.cardIwId > parseInt(id.replace("CardIw", "")));
                if (subsequentActivities && subsequentActivities.length > 0) {
                    let activities = subsequentActivities.map((a) => {
                        return a.activities;
                    });
                    return activities.flat();
                }
                else
                {
                    return [];
                }
            });
        }
        else
        {
            //Summary tab:
            setDisplayedActivities(() => {
                if (props.activitiesApiResponse && props.activitiesApiResponse.cardIws && props.activitiesApiResponse.cardIws.length > 0) {
                    return getAllActivitiesFromCardIws(props.activitiesApiResponse.cardIws);
                }
                else
                {
                    return [];
                }
            });
        }
    }

    function getAllActivitiesFromCardIws(cardIws){
        return cardIws.map((c) => {
            return c.activities.map((a) => {
                return Object.assign(a, {bgColor: getColourFromType(c.vehicleActivityType)});
            });
        }).flat();
    }

    function setCardIwTabs()
    {
        let dataSelectTabData = [];
        if (props.activitiesApiResponse && props.activitiesApiResponse.summary) {
            //Summary tab
            dataSelectTabData.push({ uid: activitiesSummaryTabId, color: summaryColor, title: `${moment(props.date).format("DD MMM YYYY")} Summary`, selected: true, key: "SUMMARY", comment: null });
        }
        if (props.activitiesApiResponse && props.activitiesApiResponse.cardIws)
        {
            props.activitiesApiResponse.cardIws.map((c) => {
                dataSelectTabData.push({ uid: `CardIw${c.cardIwId}`, color: getColourFromType(c.vehicleActivityType), title: `${moment(c.startDate).format("DD MMM YYYY HH:mm")} - ${moment(c.endDate).format("HH:mm")}`, selected: false, key: c.cardIwId, comment: c.comment });
            });
        }
        
        setDataSelectTabs(() => dataSelectTabData.map((d) => {
            return (
                <Tab className="summary-tab-item" id={d.uid} key={d.uid} >
                    <Panel color={d.color}>
                        {d.title} {d.comment !== null ? <i className={'fa fa-solid fa-comment'} ></i> : ""}
                    </Panel>
                </Tab>
            );
        }));
    }

    function handleCardIwCommentUpdate(cardIwId, comment){
        let cardIw = props.activitiesApiResponse.cardIws.find((c) => c.cardIwId === cardIwId);
        cardIw.comment = comment;
        if (liveChangesToCardIws.find((lc) => lc.cardIwId === cardIwId)) {
            setLiveChangesToCardIws(liveChangesToCardIws.map((lc) => {
                if (lc.cardIwId === cardIwId) {
                    return cardIw;
                }
                return lc;
            }))}
        else {
            setLiveChangesToCardIws([...liveChangesToCardIws, cardIw]);
        }
    }

    function setCardIwPanels()
    {
        let cardIwTabs = [];
        if (props.activitiesApiResponse && props.activitiesApiResponse.summary) {
            let startOdo = null;
            let endOdo = null;
            if (props.activitiesApiResponse.cardIws && props.activitiesApiResponse.cardIws.length > 0) {
                startOdo = props.activitiesApiResponse.cardIws[0].summary.startOdo;
                endOdo = props.activitiesApiResponse.cardIws[props.activitiesApiResponse.cardIws.length - 1].summary.endOdo;
            }
            cardIwTabs.push(
                { "id" : activitiesSummaryTabId,
                    "content": (
                    <InfoPanel 
                        drive={props.activitiesApiResponse.summary.drive}
                        work={props.activitiesApiResponse.summary.work}
                        rest={props.activitiesApiResponse.summary.rest}
                        poa ={props.activitiesApiResponse.summary.poa}
                        leftColor={summaryColor}
                        headingText={getPanelTitleFromType(null)}
                        isSummary={true}
                        startDate={props.date}
                        endDate={null}
                        startOdo={startOdo}
                        endOdo={endOdo}
                        allowAddComment={false}
                        />)
                }
            );
        }
        if (props.activitiesApiResponse && props.activitiesApiResponse.cardIws) {
            cardIwTabs.push(
                ...props.activitiesApiResponse.cardIws.map((c) => {
                    //add in any changes to the cardIws:
                    if (liveChangesToCardIws.length > 0) {
                        let liveChange = liveChangesToCardIws.find((lc) => lc.cardIwId === c.cardIwId);
                        if (liveChange) {
                            c = liveChange;
                        }
                    };
                    return (
                        {
                            "id" : `CardIw${c.cardIwId}`, 
                            "content": (
                                <InfoPanel
                                    drive={c.summary.drive}
                                    work={c.summary.work}
                                    rest={c.summary.rest}
                                    poa ={c.summary.poa}
                                    startOdo={c.summary.startOdo}
                                    endOdo={c.summary.endOdo}
                                    driverName={c.driverName}
                                    leftColor={getColourFromType(c.vehicleActivityType)}
                                    headingText={getPanelTitleFromType(c.vehicleActivityType, c.journeyName)}
                                    isSummary={false}
                                    startDate={c.startDate}
                                    endDate={c.endDate}
                                    showBurger={true}
                                    cardIwId={c.cardIwId}
                                    initialComment={c.comment}
                                    onCommentChange={handleCardIwCommentUpdate}
                                    burgerItems={getActionsFromActivityType(c.vehicleActivityType, c.cardIwId)}
                                    allowAddComment={true}
                                />
                            )
                        }
                    );
                })
            );
        }
        setCardIwPanelData(cardIwTabs);
    }

    function onShowAssignDriverModalChange(show, cardIwId){
        setShowAssignDriverModal(show);
        let cardIwObject = props.activitiesApiResponse.cardIws.find((c) => c.cardIwId === cardIwId);
        setAssignDriverModalCardIw(cardIwObject);
    }

    function onUnassignDriverModalChange(show, cardIwId){
        setShowUnassignModal(show);
        if (cardIwId) {
            let cardIwObject = props.activitiesApiResponse.cardIws.find((c) => c.cardIwId === cardIwId);
            setUnassignModalCardIw(cardIwObject);
        }
        else {
            setUnassignModalCardIw(null);
        }
    }

    function getActionsFromActivityType(vehicleActivityType, cardIwId){
        let actions = [];

        if (vehicleActivityType === vehicleUploadedDrivingNoCard && canAssignDrivers) {
            actions.push(<MenuItem onClick={() => onShowAssignDriverModalChange(true, cardIwId)} text="Assign Driver"  key={`${cardIwId}-mi-assign-driver`} />);
        }
        if (vehicleActivityType === driverAssignedToVehicleData && canAssignDrivers) {
            actions.push(<MenuItem onClick={() => onUnassignDriverModalChange(true, cardIwId)} text="Unassign Driver"  key={`${cardIwId}-mi-unassign-driver`} />);
        }
        return actions;
    }

    function onActivityTabChange(id) {
        setDisplayedActivitiesData(id);
        setHighlightedActivity(null);
    }

    function handleHighlightActivity(activity) {
        setHighlightedActivity(activity);
    }

    return (
        <div>
            <AssignDriverModal 
                showModal={showAssignDriverModal}
                onCloseModal={() => onShowAssignDriverModalChange(false)}
                assetDetails={props.assetDetails}
                date={props.date}
                cardIw={assignDriverModalCardIw}
            />
            <UnassignDriverModal
                isOpen={showUnassignModal}
                onClose={() => onUnassignDriverModalChange(false)}
                cardIw={unassignModalCardIw}
                assetId={props.assetDetails.assetId.toString()}

            />
            <div>
                <div className="button-row button-row-stacked-mobile spacer-bottom">
                    <ReportList lastSearchPayload={{ filters : [{"key" : "assetId", "value" : props.assetDetails.assetId}], "startDate" : props.date, "endDate" : props.date}} pageName="DayDetail-Activities" popoverUsePortal={false} onReportDownloadingChange={onReportDownloading} />
                    <Button text={"Get File"} intent="Primary" onClick={OnGetFilesClick} disabled={!firstUploadId || uploadDownloading} />
                    <div className="push-right">
                        <FormDatePeriodSelector
                            id="listing-period-activities-tab"
                            periodType="day"
                            periodsToShow={0}
                            startDate={initialDate}
                            onChange={onDayChange}
                            dateFormat={periodSelectorDateFormat}
                            showCalendar={true}
                            showText={false}
                            usePortal={false}
                        />
                    </div>
                </div>
                <NotificationInline intent="success" text="Your report has been queued, and will download as soon as it's ready." show={reportDownloading} />
            </div>
            <div id="graph">
                    <ActivitiesGraph 
                        activitiesList={displayedActivities} 
                        undisplayedActivitiesList={undisplatedActivities} 
                        isLoading={props.isLoading} 
                        isSummary={activitiesSummaryTabId === currentActivityTab} 
                        baseDate={props.date} 
                        highlightedActivity={highlightedActivity} 
                    />
            </div>
            <div className="summary-tab-list">
                <ShowHide evaluator={!props.isLoading}
                show={
                    <Tabs className="summary-tab-list" onChange={(p) => onActivityTabChange(p)} animate={false}>
                        {dataSelectTabs}
                    </Tabs>
                }
                hide={<Tabs className="summarty-tab-list"><Tab className="summary-tab-item"><Panel className="summary-tab-item-disabled" color="#333333" >Loading...</Panel></Tab></Tabs>} />
            </div>
            <div id="content">
                <div id="listing">
                    <h3>Activities</h3>
                    <ActivitiesList activitiesList={displayedActivities} isLoading={props.isLoading} onActivitySelected={handleHighlightActivity} id="activities-tab-activities-list" />
                </div>
                <div id="info-panels">
                    <ShowHide evaluator={!props.isLoading} 
                    hide={
                        <InfoPanel disabled={true} startDate={props.date} headingText="" />
                    } show={
                    <>
                        {cardIwPanelData.find((c) => c.id === currentActivityTab)?.content}
                        {harshBrakingPanelData}
                    </>
                    } />
                </div>
            </div>

        </div>
    )
}

ActivitiesTab.defaultProps = {
    lastSearchPayload: {},
    isLoading: false

};

ActivitiesTab.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    date: PropTypes.string,
    lastSearchPayload: PropTypes.object,
    activitiesApiResponse: PropTypes.object,
    harshBrakingApiResponse: PropTypes.object,
    isLoading: PropTypes.bool,
    vehicleActivityTypes: PropTypes.array.isRequired,
    onDateChanged: PropTypes.func.isRequired
};