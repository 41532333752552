import React, {useState} from 'react';
import { Modal, NotificationToaster } from 'components/notifications';
import { Button } from 'components/buttons';
import moment from 'moment';
import { AssetService } from 'services';
import { Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";

export function UnassignDriverModal(props) {

    const [unassignDriverModalIsSaving, setUnassignDriverModalIsSaving] = useState(false);

    const bufferSeconds = 5;

    function handleUnassignDriver()
    {
        setUnassignDriverModalIsSaving(true);
        let startDate = moment(props.cardIw.startDate).add(-1 * bufferSeconds, 'second').format("YYYY-MM-DDTHH:mm:ss");
        let endDate = moment(props.cardIw.endDate).add(bufferSeconds, 'second').format("YYYY-MM-DDTHH:mm:ss");
        AssetService.unassignDriverFromCardIw(props.assetId, startDate, endDate)
        .then(() => {
            NotificationToaster.show(Intent.SUCCESS, "Driver unassigned successfully.", false);
            setUnassignDriverModalIsSaving(false);
            onUnassignDriverModalClose();
        }, (error) => {
            NotificationToaster.show(Intent.DANGER, error, false);
            setUnassignDriverModalIsSaving(false);
        });
    }

    function onUnassignDriverModalClose(){
        props.onClose();
    }

    return (
        <Modal 
            title="Unassign Driver"
            isOpen={props.isOpen}
            isCloseButtonShown={true}
            onClose={onUnassignDriverModalClose}
            canClickOutsideToClose={true}
            updateStateOnClose={false}
        >
            <p>Are you sure you want to Un-assign the driver from the shift?</p>
            <div className="button-row">
                <Button text="Unassign Driver" intent="Primary" onClick={handleUnassignDriver} disabled={unassignDriverModalIsSaving} />
                <Button text="Cancel" intent="Secondary" onClick={onUnassignDriverModalClose} disabled={unassignDriverModalIsSaving} />
            </div>
        </Modal>
    );
}

UnassignDriverModal.defaultProps = {

}

UnassignDriverModal.propTypes = {
    cardIw: PropTypes.object.isRequired,
    assetId: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}