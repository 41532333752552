import React from "react";
import './Key.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { KeyBlock } from 'components/key';

export function Key(props) {
    return (
        <div className={classNames("listing-key", { "bp3-skeleton": props.loading, "vertical": props.listVertical, "listing-key-padding": props.hasPadding })}>
            {props.items && props.items.map(function (item) {
                return (
                    <div key={item.name} className="key-wrapper">
                        <KeyBlock className={item.className} rounded={props.rounded} colours={item.colours} icon={item.icon} iconColour={item.iconColour} hideBorder={item.hideBorder} />
                        <label>{item.name}</label>
                    </div>
                );
            })}
        </div>
    )
}

Key.defaultProps = {
    items: [],
    loading: false,
    listVertical: false,
    rounded: false,
    hasPadding: true,

};

Key.propTypes = {
    items: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    listVertical: PropTypes.bool,
    rounded: PropTypes.bool,
    hasPadding: PropTypes.bool
};