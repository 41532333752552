import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { NonIdealState, Icon } from "@blueprintjs/core";
import { ListingPagination } from 'components/listing';
import { FormSelect } from 'components/form-fields';
import { ShowHide } from 'components/layout/ShowHide';
import 'components/listing/Listing.css';
import { Table } from 'components/listing/Table';

const rows25 = 25;
const rows50 = 50;
const rows100 = 100;

const perPageOptions = [rows25, rows50, rows100];

export function ListingTable(props) {

    const [perPage, setPerPage] = useState(perPageOptions[perPageOptions.indexOf(props.pageSize)]);
    const [currentPage, setCurrentPage] = useState(props.startPage ?? 1);
    const [totalPages, setTotalPages] = useState(1);
    const dummyLoadData = [];
    for (let i = 0; i < perPage; i++) {

        let cols = []

        for (let j = 0; j < props.headers.length; j++) {
            const maxLength = 36;
            cols.push(Math.random().toString(maxLength).replace(/[^a-z]+/g, ''));
        }

        dummyLoadData.push({
            key: i,
            cells: cols
        });
    }

    useEffect(() => {
        if (props.startPage) {
            setCurrentPage(props.startPage);
        }
    }, [props.startPage]);

    useEffect(() => {
        let pageCount = Math.max(1, Math.ceil(props.totalRecordCount / perPage));
        setTotalPages(pageCount);
        setCurrentPage(1);
    }, [perPage, props.totalRecordCount]);

    function onPageSizeChange(item) {
        if (item.id !== perPage) {
            setPerPage(item.id);
            setCurrentPage(1);
            props.onPagingChange(1, item.id);
        }
    }

    function onPaginationChange(page) {
        if (page !== currentPage) {
            setCurrentPage(page);
            props.onPagingChange(page, perPage);
        }
    }

    function getHeaderName(headerName) {
        if (typeof headerName === 'string') {
            return headerName;
        }
        else
        {
            return headerName.props.children;
        }
    }

    function renderTableHeaderCell(headerName) {
        let matchedSort = false;
        let matchedMapping;

        //The sortableHeaders can either be list of string or list of {headerName: sortName:} objects
        //The 'headerName' can either be a string or a div element
        if (props.sortable && props.sortableHeaders?.length > 0) {
            if (typeof props.sortableHeaders[0] === 'string') {
                if (typeof headerName === 'string') {
                    matchedSort = props.sortableHeaders.includes(headerName);
                }
                else
                {
                    matchedSort = props.sortableHeaders.includes(headerName.key);
                }
            }
            else {
                matchedMapping = props.sortableHeaders.find(
                    function (sortHead) {
                        if (typeof headerName === 'string') {
                            return sortHead.headerName === headerName;
                        }
                        else
                        {
                            return sortHead.headerName === headerName.key;
                        }
                    }
                );
            }
        }

        if (!matchedSort && !matchedMapping) {
            return headerName;
        } else {
            var icon = null;

            if ((matchedSort && getHeaderName(headerName) === props.sortedBy) ||
                (matchedMapping && matchedMapping?.headerName === props.sortedBy?.headerName)) {
                var iconName = props.sortedDir === 'A' ? "caret-down" : "caret-up";
                icon = (<Icon icon={iconName} />);
            }

            return (<span className="list-table-clickable-header" onClick={() =>
                onTableHeaderSort(matchedMapping ? matchedMapping : headerName)}>
                {matchedMapping ? matchedMapping.headerName : headerName} {icon}
            </span>);
        }
    }

    function onTableHeaderSort(header) {
        let sortDir;
        if (typeof header === 'string') {
            sortDir = props.sortedDir === 'A' && props.sortedBy === header ? 'D' : 'A';
        }
        else {
            sortDir = props.sortedDir === 'A' && props.sortedBy.headerName === header.headerName ? 'D' : 'A';
        }

        if (props.onSort != null) {
            props.onSort(header, sortDir);
        }
    }

    function formatHeaders() {
        return {
            headers: props.headers.map((value) => {
                let thisKey = value;
                if (!(typeof value === 'string')) {
                    thisKey = value.key;
                }
                return {
                    key: thisKey,
                    value: renderTableHeaderCell(value)
                }
            })
        };
    }

    function getRowClass(rowIndex) {
        if (!props.rowClasses) {
            return null;
        }
        if (props.rowClasses.length > rowIndex -1) {
            return props.rowClasses[rowIndex];
        }
        return null;
    }

    function formatData() {
        return props.data.map((row, rowIndex) => {
            return {
                cells: row,
                key: rowIndex,
                onRowClick: props.rowClickEnabled ? () => props.onRowClick(rowIndex) : null,
                class: getRowClass(rowIndex)
            };
        });
    }

    return (
        <div id={props.id}>
            <ShowHide
                evaluator={props.totalRecordCount === 0 && !props.loadingData}
                show={(
                    <Fragment>
                        <div className="clear-divide"></div>
                        <div className="list-table-no-results">
                            <NonIdealState
                                title={props.noDataHeading ? props.noDataHeading : "Nothing to see here..."}
                                description={props.noDataMessage}
                            />
                        </div>
                    </Fragment>

                )}
                hide={(

                    <Fragment>
                        <ShowHide
                            evaluator={props.pageable}
                            show={(
                                <Fragment>
                                    <div className="pull-right listing-table-filter-fields listing-table-per-page">
                                        Show
                                        <FormSelect
                                            items={perPageOptions.map(m => { return { id: m, name: m + " rows" } })}
                                            placeholder="Items"
                                            onItemSelect={onPageSizeChange}
                                            selectedValue={perPage}
                                            disabled={props.loadingData}
                                            dynamicWidth={true}
                                        ></FormSelect>
                                        per page
                                    </div>

                                    <div className="clear-divide"></div>
                                </Fragment>
                            )}
                        ></ShowHide>
                        <div className={classNames(props.allowTableScroll ? "listing-table-scroll" : "", props.allowTableScrollVertical ? "listing-table-scroll-vertical" : "")}>
                        <Table
                            data={props.totalRecordCount === 0 && props.loadingData ? dummyLoadData : formatData()}
                            headerData={formatHeaders()}
                            loadingData={props.loadingData}
                            noWrap={props.noWrap}
                            shrinkLastColumn={props.shrinkLastColumn}
                            columnClasses={props.columnClasses}
                            loadingClass={props.loadingClass}
                        />
                        </div>
                        <ShowHide
                            evaluator={props.pageable}
                            show={(
                                <ListingPagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPaginationChange} loadingData={props.loadingData}></ListingPagination>
                            )}
                        ></ShowHide>
                    </Fragment>

                )}
            ></ShowHide>
        </div>
    );
}

ListingTable.defaultProps = {
    id: null,
    loadingData: true,
    data: [],
    headers: [],
    totalRecordCount: 0,
    sortable: false,
    sortableHeaders: null,
    sortedBy: '',
    sortedDir: 'A',
    onSort: null,
    pageable: true,
    startPage: null,
    shrinkLastColumn: false,
    noWrap: false,
    pageSize: 25,
    allowTableScroll : false,
    allowTableScrollVertical: false,
    columnClasses : [],
    rowClasses: [],
    loadingClass : "bp3-skeleton",
    rowClickEnabled: false
};

ListingTable.propTypes = {
    id: PropTypes.string,
    loadingData: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    totalRecordCount: PropTypes.number.isRequired,
    onPagingChange: PropTypes.func,
    noDataMessage: PropTypes.string,
    noDataHeading: PropTypes.string,
    sortable: PropTypes.bool,
    sortableHeaders: PropTypes.array,
    sortedBy: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    sortedDir: PropTypes.string,
    onSort: PropTypes.func,
    pageable: PropTypes.bool,
    startPage: PropTypes.number,
    shrinkLastColumn: PropTypes.bool,
    noWrap: PropTypes.bool,
    allowTableScroll : PropTypes.bool,
    allowTableScrollVertical: PropTypes.bool,
    columnClasses: PropTypes.array,
    rowClasses: PropTypes.array,
    loadingClass : PropTypes.string,
    rowClickEnabled: PropTypes.bool,
    onRowClick: PropTypes.func,
    pageSize: PropTypes.oneOf(perPageOptions)
};
