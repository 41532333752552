import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from "moment";

export function DateToLocal(props) {

	const [dateOutput, setDateOutput] = useState("");

	useEffect(() => {
		
		var dateUtcMoment = moment.utc(props.children);
		
		if (dateUtcMoment.isValid()) {
			var dateUtc = dateUtcMoment.toDate();
			setDateOutput(moment(dateUtc).local().format(props.format));
		} else {
			setDateOutput(props.invalidDateString);
		}
	}, [props.children]);


	return (
		<Fragment>{dateOutput}</Fragment>
	);
}

DateToLocal.defaultProps = {
	format: "DD/MM/YYYY HH:mm",
	invalidDateString: "-"
};

DateToLocal.propTypes = {
	format: PropTypes.string,
	invalidDateString: PropTypes.string
};