//Majority of source code taken from https://github.com/JamesRandall/react-azure-adb2c
//Updated to work with a later version of the msal library for ie11 support

import * as Msal from "@azure/msal-browser";
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const state = {
    accessToken: null,
    signedIn: false
}

var msalObj;
var tokenRequestObj;
var loginRequestObj;

function acquireToken(successfulCallback) {

    //Get all tokens from storage
    const currentAccounts = msalObj.getAllAccounts();

    //If there is nothing in storage, redirect the user to login
    if (currentAccounts.length === 0) {
        redirectToLogin(successfulCallback);
    } else if (currentAccounts.length > 1) {
        //We have found more than 1 account, we should log them out
        authentication.signOut();
    } else if (currentAccounts.length === 1) {
        //SSO already exists, lets get a new token if needed
        acquireSilentToken(successfulCallback);
    }
}

function redirectToLogin(successfulCallback) {

    //Set up the callback for getting a token, this is used by the redirect and by getting a silent token
    msalObj.handleRedirectPromise()
    .then((response) => {
        handleResponse(response, successfulCallback);
    })
    .catch(() => {
        //Something has gone wrong, try to log them out
        authentication.signOut();
    });
}

function handleResponse(response, successfulCallback) {

    //If there is a response, then this is a callback, otherwise it is the first pass through
    if (response) {

        // if response contains an access token, store it and we have logged in successfully
        if (response.accessToken && response.accessToken !== "") {
            state.signedIn = true;
            state.accessToken = response;

            if (successfulCallback) {
                successfulCallback();
            }
        }

        //If a cookie was set for the return page, we can now just switch to it.
        var redirectUrl = Cookies.get('return');
        if (redirectUrl != null) {
            Cookies.remove('return');
            window.location = redirectUrl;
        }

    } else {

        //no callback, so can navigate the user to the login screen.
        msalObj.loginRedirect(loginRequestObj);
    }
}

function acquireSilentToken(successfulCallback) {

    //User account already present, just refresh the token if needed.
    var request = {
        ...tokenRequestObj
    };
    request.account = msalObj.getAllAccounts()[0];

    return msalObj.acquireTokenSilent(request)
        .then((response) => {
            // In case the response from B2C server has an empty accessToken field
            // throw an error to initiate token acquisition
            if (!response.accessToken || response.accessToken === "") {
                throw new Msal.InteractionRequiredAuthError;
            }
            return handleResponse(response, successfulCallback);
        })
        .catch(error => {

            localStorage.clear();

            //If getting a token silently fails, then we redirect them to get a token
            if (error instanceof Msal.InteractionRequiredAuthError) {
                return msalObj.acquireTokenRedirect(request);
            }

            return null;
            
        });
}

export const authentication = {
    initialize: (msalConfig, loginRequest, tokenRequest) => {
        msalObj = new Msal.PublicClientApplication(msalConfig);
        tokenRequestObj = tokenRequest;
        loginRequestObj = loginRequest;
    },
    run: (successfulCallback) => {
        acquireToken(successfulCallback);
    },
    rechallenge: () => {
        localStorage.clear();
        redirectToLogin();
    },
    required: (WrappedComponent) => {
        return function RequiredAccess() {

            const [signedIn, setSignedIn] = useState(null);

            useEffect(function () {
                acquireToken(() => {
                    setSignedIn(true);
                });
            }, []);

            return signedIn === true ? (<WrappedComponent />) : null;
        };
    },
    onHoldRedirect: () => {
        window.location = window.env.VISION_URL;
    },
    anonymousAccess: (WrappedComponent) => {
        return function AnonymousAccess() {

            const [signedIn, setSignedIn] = useState(null);

            useEffect(function () {
                const currentAccounts = msalObj.getAllAccounts();
                if (currentAccounts.length === 0) {
                    setSignedIn(false);
                } else {
                    window.location = "/";
                }
            }, []);

            return signedIn === false ? (<WrappedComponent />) : null;
        };
    },
    signOut: () => {

        var token = authentication.getAccessToken();
        var logoutConfig = {};

        if (token != null && token.idTokenClaims != null && token.idTokenClaims.extension_SignOutUrl != null) {
            logoutConfig.postLogoutRedirectUri = token.idTokenClaims.extension_SignOutUrl;
        }
        
        msalObj.logout(logoutConfig);
    },
    getAccessToken: () => {
        return state.accessToken;
    }
}