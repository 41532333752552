import React, { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import PropTypes from "prop-types";
import moment from "moment";
import { NotificationInline, NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";
import { AssetService, BlobStorageService } from "services";
import { FormDatePeriodSelector, FormHeading } from "components/form-fields";
import { Key } from "components/key";
import { Button } from "components/buttons";
import { ReportList } from "components/reporting";
import './SpeedDataTab.css';

export function SpeedDataTab(props) {
    const { assetId, date, resetTabs, harshBrakingData } = props;
    const periodSelectorDateFormat = "DD MMM YYYY";
    const minutesInDay = 1440;
    const minutesInHour = 60;
    const halfWay = 50;
    const graphKey = [
        { id: 1, name: "Top speed per minute", colours: "#0C2235" },
        { id: 2, name: "Harsh braking occurred", colours: "#F0578A" },
        { id: 3, name: "Average speed per minute", icon: "minus", colours: "#FFFFFF", iconColour: "#66BAFF", hideBorder: true },
        { id: 4, name: "Authorised speed", icon: "minus", colours: "#FFFFFF", iconColour: "#BDC6FA", hideBorder: true }
    ]
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const [sliderStartPoint, setSliderStartPoint] = useState(halfWay);
    const [reportDownloading, setReportDownloading] = useState(false);
    const [uploadDownloading, setUploadingDownloading] = useState(false);
    const [graphData, setGraphData] = useState({stack1: [], stack2: [], stack3: [], stack4: [], line1: []});

    let firstUploadId;
    if (props.activitiesApiResponse && props.activitiesApiResponse.uploads && props.activitiesApiResponse.uploads.length !== 0) {
        firstUploadId = props.activitiesApiResponse.uploads[0].uploadId;
    }

    useEffect(() => {
        if (assetId && date && harshBrakingData) {
            getSpeedData(assetId, date);
        }
    }, [assetId, date]);

    useEffect(() => {
        if (selectedDate) {
            getSpeedData(assetId, selectedDate);
        }

    }, [selectedDate]);

    function getSpeedData(speedDataAssetId, speedDataDate) {
        setIsLoading(true);
        let tempDates = [];
        let tempMinSpeedData = [];
        let tempBeforeSpeedData = [];
        let tempAverageSpeedData = [];
        let tempAfterSpeedData = [];
        let authorisedSpeed = [];
        let isSliderSet = false;
        let harshBreakingTimes = harshBrakingData.map(x => moment(x.startDate).format("HH:mm"));

        AssetService.getSpeedDataForAsset(speedDataAssetId, speedDataDate).then((response) => {
            if (response != null) {
                for (let hour = 0; hour < 24; hour++) {
                    for (let minute = 0; minute < 60; minute++) {
                        let isHarshBreakingForTime = false;
                        let formattedHour = hour < 10 ? `0${hour}` : hour;
                        let formattedMinute = minute < 10 ? `0${minute}` : minute;
                        let formattedTime = `${formattedHour}:${formattedMinute}`;
                        tempDates.push(formattedTime);

                        let speedDataForMinute = response.speedData.find(x => x.hour === formattedHour && x.minute === formattedMinute);

                        if(harshBreakingTimes.includes(formattedTime)) {
                            isHarshBreakingForTime = true;
                        }

                        if (speedDataForMinute) {
                            if (!isSliderSet) {
                                let totalMinutes = (hour * minutesInHour) + minute;
                                setSliderStartPoint(totalMinutes / minutesInDay * 100);
                                isSliderSet = true;
                            }

                            tempMinSpeedData.push({
                                value: speedDataForMinute.min,
                                colour: isHarshBreakingForTime ? '#F0578A': '#0C2235'
                            });
                            tempBeforeSpeedData.push({
                                value: speedDataForMinute.average - speedDataForMinute.min - 1,
                                colour: isHarshBreakingForTime ? '#F0578A': '#0C2235'
                            });
                            tempAverageSpeedData.push({
                                value: 1,
                                colour: '#66BAFB'
                            });
                            tempAfterSpeedData.push({
                                value: speedDataForMinute.max - speedDataForMinute.average,
                                colour: isHarshBreakingForTime ? '#F0578A': '#0C2235'
                            });
                        }
                        else {
                            tempMinSpeedData.push({value: 0});
                            tempBeforeSpeedData.push({value: 0});
                            tempAverageSpeedData.push({value: 0});
                            tempAfterSpeedData.push({value: 0});
                        }

                        authorisedSpeed.push(response.authorisedSpeed);
                    }
                }

                setDates(tempDates);
                setGraphData({
                    stack1: tempMinSpeedData, 
                    stack2: tempBeforeSpeedData, 
                    stack3: tempAverageSpeedData,
                    stack4: tempAfterSpeedData, 
                    line1: authorisedSpeed}
                );
                setIsLoading(false);
            }
        }).catch(() => {
            resetTabs(moment(selectedDate).format("YYYY-MM-DD"));
        });
    }

    function onDayChange(day) {
        setSelectedDate(day);
    }

    function OnGetFilesClick() {
        setUploadingDownloading(true);
        AssetService.getDownloadLinkForVehicleUnitFile(firstUploadId).then((response) => {
            BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName);
            setUploadingDownloading(false);
        }, (error) => {
            setUploadingDownloading(false);
            NotificationToaster.show(Intent.DANGER, `Failed to get File. ${error}`, false);
        });
    }

    function onReportDownloading(isDownloading) {
        setReportDownloading(isDownloading);
    }

    return (
        <div>
            <div className="spacer-top">
                <div className="inline-items button-row button-row-stacked-mobile spacer-bottom">
                    <ReportList lastSearchPayload={{ filters : [{"key" : "assetId", "value" : props.assetId}], "startDate" : props.date, "endDate" : props.date}} pageName="DayDetail-Speed" popoverUsePortal={false} onReportDownloadingChange={onReportDownloading} />
                    <Button text={"Get File"} intent="Primary" onClick={OnGetFilesClick} disabled={!firstUploadId || uploadDownloading} />
                    <div className="push-right">
                        <FormDatePeriodSelector
                            id="listing-period-new"
                            periodType="day"
                            periodsToShow={0}
                            startDate={date}
                            onChange={onDayChange}
                            dateFormat={periodSelectorDateFormat}
                            showCalendar={true}
                            showText={false}
                            usePortal={false}
                        />
                    </div>
                </div>
                <NotificationInline intent="success" text="Your report has been queued, and will download as soon as it's ready." show={reportDownloading} />
            </div>
            <div className="graph-size-outer">
                <ReactECharts showLoading={isLoading} className="graph-size-inner" option={{
                    tooltip: {
                        trigger: 'axis',
                        position: function (pt) {
                            return [pt[0], '10%'];
                        },
                        formatter: function (params) {
                            let min = params[0].value === 0 ? 0 : params[0].value;
                            let average = params[0].value + params[1].value + params[2].value;
                            let max = average + params[3].value;
                            return `<b>${moment(props.date).format(periodSelectorDateFormat)}</b> ${params[0].name} <br />Range: ${min} - ${max} Kph <br />Average: ${average} Kph`;
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: dates
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: [0, '100%'],
                        name: 'Speed (KPH)',
                        max: 120
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: sliderStartPoint,
                            end: sliderStartPoint + 10,
                        },
                        {
                            type: 'slider',
                            start: sliderStartPoint,
                            end: sliderStartPoint + 10,
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            stack: 'x',
                            data: graphData.stack1.map(x => {
                                return {
                                    value: x.value,
                                    itemStyle: {
                                        color: x.colour,
                                        borderColor: x.colour
                                    }
                                }
                            }),
                        },
                        {
                            type: 'bar',
                            stack: 'x',
                            data: graphData.stack2.map(x => {
                                return {
                                    value: x.value,
                                    itemStyle: {
                                        color: x.colour,
                                        borderColor: x.colour
                                    }
                                }
                            }),
                        },
                        {
                            type: 'bar',
                            stack: 'x',
                            data: graphData.stack3.map(x => {
                                return {
                                    value: x.value,
                                    itemStyle: {
                                        color: x.colour,
                                        borderColor: x.colour
                                    }
                                }
                            }),
                        },
                        {
                            type: 'bar',
                            stack: 'x',
                            data: graphData.stack4.map(x => {
                                return {
                                    value: x.value,
                                    itemStyle: {
                                        color: x.colour,
                                        borderColor: x.colour
                                    }
                                }
                            }),
                        },
                        {
                            type: 'line',
                            data: graphData.line1,
                            showSymbol: false,
                            itemStyle: {
                                color: '#BDC6FA'
                            }
                        }
                    ]
                }} />
            </div>
            <div className="key-bottom">
                <FormHeading headingLevel="h3">Key</FormHeading>
                <Key hasPadding={false} loading={isLoading} items={graphKey} />
            </div>
        </div>
    )
}

SpeedDataTab.defaultProps = {
    assetId: 0,
    date: PropTypes.string,
    resetTabs: () => { },
    harshBrakingData: []
};

SpeedDataTab.propTypes = {
    assetId: PropTypes.number,
    date: PropTypes.string,
    resetTabs: PropTypes.func,
    harshBrakingData: PropTypes.array,
};