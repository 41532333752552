import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { DataDisplayTable } from 'components/data-display';
import { DateToLocal } from 'components/dates';
import { FormHeading, FormTextInput } from 'components/form-fields';
import { ListingTable } from 'components/listing';
import { Breadcrumbs } from 'components/navigation';
import { StatusBlock } from 'components/status';
import { TrendStatus } from 'components/status/TrendStatus';
import { UserAccess } from 'components/user-access';
import { HelperFunctions } from 'helpers';
import { useDebounce } from 'hooks/useDebounce';
import { RiskService } from 'services/RiskService';
import { PageRestricted } from 'pages/errors/page-restricted';
import { RiskSelector } from 'pages/risk/shared/RiskSelector';
import { useSelector } from 'react-redux';
import { Numeric } from 'components/formatting';

export function RiskEmployeeListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [loading, setLoading] = useState(true);
    const [locationsChanged, setLocationsChanged] = useState(true);
    const [periodStats, setPeriodStats] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [periodNumber, setPeriodNumber] = useState(0);
    const [locationList, setLocationList] = useState({});

    const statsPerRow = 4;

    const tableHeaders = ["Employee", "Risk Score", "Trend", "Location Position", "Company Position", "Location"];
    const sortableHeaders = [
        { headerName: "Employee", sortName: "EMPLOYEENAME" },
        { headerName: "Risk Score", sortName: "SCORE" },
        { headerName: "Trend", sortName: "TREND" },
        { headerName: "Location Position", sortName: "LOCATIONPOSITION" },
        { headerName: "Company Position", sortName: "COMPANYPOSITION" },
        { headerName: "Location", sortName: "LOCATION" },
    ];

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [searchTerm, setSearchTerm] = useState("");
    const [tableSortBy, setTableSortBy] = useState(sortableHeaders[1]);
    const [tableSortDir, setTableSortDir] = useState("D");

    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();

    const searchOptions = useSelector(state => state.filters["riskSearch"]);

    useEffect(function () {
        if (searchOptions.period != null) {
            setPeriodNumber(searchOptions.period);
            setLocationList(searchOptions.locations);
        }
    }, [searchOptions]);


    function onSearchLoad(searchPreferences) {

        setLocationList(searchPreferences.locations);
        setPeriodNumber(searchPreferences.period);
    }

    useEffect(function () {
        if (locationList.length > 0 && periodNumber !== 0) {
            fetchEmployeeListing(true);
        }
    }, [locationList, periodNumber])


    function fetchEmployeeListing(forceRefresh = false) {

        if (periodNumber === 0) {
            return;
        }

        if (currentPageNumber.current === pageNumber &&
            currentPageSize.current === pageSize &&
            currentSearchTerm.current === debouncedSearchTerm &&
            currentTableSortBy.current === tableSortBy.sortName &&
            currentTableSortDir.current === tableSortDir &&
            !forceRefresh
        ) {
            return;
        }

        currentPageNumber.current = pageNumber;
        currentPageSize.current = pageSize;
        currentSearchTerm.current = debouncedSearchTerm;
        currentTableSortBy.current = tableSortBy.sortName;
        currentTableSortDir.current = tableSortDir;

        setLoading(true);

        RiskService.getEmployeeListing(pageSize, pageNumber, debouncedSearchTerm, tableSortBy.sortName, tableSortDir, locationList, periodNumber).then(mapListingData).finally(function () {
            setLoading(false);
            setLocationsChanged(false);
        });
    }

    function mapListingData(response) {
        var stats = [
            {
                header: "Risk Period",
                value: <span className="inline-items"><h3>#{response.period?.number}</h3> <em style={{ 'fontSize': '13px' }}><DateToLocal format="DD/MM/YYYY">{response.period?.startDate}</DateToLocal> - <DateToLocal format="DD/MM/YYYY">{response.period?.endDate}</DateToLocal></em></span>,
                key: 'risk-period-dates'
            },
            {
                header: "Worst Performing Location",
                value: response.worstLocation,
                key: 'current-events'
            },
            {
                header: "Best Performing Location",
                value: response.bestLocation,
                key: 'risk-employee-count'
            }
        ];

        response.bandCounts?.forEach(function (band) {
            stats.push({
                header: `${band.name} Employees`,
                value: <Numeric>{band.count}</Numeric>,
                key: `${HelperFunctions.kebabCase(band.name)}-count`
            })
        });

        setPeriodStats(stats);
        setTotalRecords(response.totalCount);

        setTableRows(response.data?.map(function (e) {
            return [
                <Link key={`link-${e.employeeId}`} to={`/risk/employee/${e.employeeId}`}>{e.employeeName}</Link>,
                generateStatusBlock(e.score),
                <TrendStatus trend={e.trend} key={`trend-${e.employeeId}`} />,
                <span key={`position-location-${e.employeeId}`}><Numeric>{e.location?.position}</Numeric>/<Numeric>{e.location?.total}</Numeric></span>,
                <span key={`position-company-${e.employeeId}`}><Numeric>{e.company?.position}</Numeric>/<Numeric>{e.company?.total}</Numeric></span>,
                e.location?.name
            ];
        }));
    }

    function generateStatusBlock(riskScore) {

        if (riskScore?.band == null) {
            return <StatusBlock background="EFEFEF">No score available</StatusBlock>
        }

        return <StatusBlock background={riskScore.bandColour}><Numeric>{riskScore.total}</Numeric> - {riskScore.band}</StatusBlock>;
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    }

    function onTableSort(header, direction) {
        setTableSortDir(direction);
        setTableSortBy(header);
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
    }

    useEffect(() => {
        fetchEmployeeListing();
    }, [pageSize, pageNumber, tableSortBy, tableSortDir, debouncedSearchTerm]);

    return (

        <UserAccess perform={["Risk:View"]}
            yes={() => (
                <div className="row">

                    <Breadcrumbs items={props.breadcrumbs} />
                    <FormHeading headingLevel="h1">Employee Risk</FormHeading>

                    <RiskSelector onLoaded={onSearchLoad} disabled={loading} />


                    <div className="spacer-bottom">
                        <DataDisplayTable
                            data={periodStats}
                            displayInRows={false}
                            loading={loading && locationsChanged}
                            columnsPerRow={statsPerRow}
                        />
                    </div>

                    <div className="pull-left">
                        <FormTextInput placeholder="Employee Search" disabled={loading} onChange={onSearchChange} value={searchTerm} large icon="search" id="risk-employee-search-field" />
                    </div>

                    <ListingTable
                        id="listing-table-risk-employee"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loading}
                        noDataMessage={"No results were found for your search criteria"}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />

                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}