import { Intent } from '@blueprintjs/core';
import { Button, ButtonSave, LinkButton } from 'components/buttons';
import { FormSuggest, FormDateInput, FormLocationSelector } from 'components/form-fields';
import { AlertUnsavedData, NotificationToaster, NotificationInline } from 'components/notifications';
import { useDirtyData } from 'hooks/useDirtyData';
import React, { useEffect, useState, Fragment } from 'react';
import { JobService, UserService, AssetService } from 'services';
import { UserAccess, UserHasAccess } from 'components/user-access/UserAccess';
import { DataDisplayTable } from "components/data-display";
import axios from 'axios';
import moment from "moment";
import { useValidation } from "hooks/useValidation";
import { ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { FailVisit } from 'pages/job/edit/shared';
import { HelperFunctions } from 'helpers';
import { DateToLocal } from "components/dates";

export function JobDetails(props) {
    const defaultJobDetails = {
        jobId: null,
        state: null,
        productName: null,
        productDescription: null,
        intendedAssetId: null,
        assetType: null,
        locationId: null,
        dueDate: null,
        scheduledDate: null,
        flsStatus: null,
        jobNote: null,
        jobProductBusinessAreaId: null,
        completed:null
    };

    const [isValid, errors, validate] = useValidation();
    const [jobDetails, setJobDetails] = useState(defaultJobDetails);
    const [originalJobDetails, setOriginalJobDetails] = useState(defaultJobDetails);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [assets, setAssets] = useState([]);
    const [assetSearch, setAssetSearch] = useState('');
    const isDataDirty = useDirtyData(originalJobDetails, jobDetails);
    const [canSave, setCanSave] = useState(false);
    const [jobIsFailed, setJobIsFailed] = useState(false);
    const [jobCanBeFailed, setJobCanBeFailed] = useState(false);
    const [amendmentRequest, setAmendmentRequest] = useState(null);
    const monthsInPastDefault = 240;
    const minDateDefault = moment().subtract(monthsInPastDefault, 'months').toDate();
    const [minIntendedDate, setMinIntendedDate] = useState(minDateDefault);
    const [showLocationsAsHierarachy, setShowLocationsAsHierarachy] = useState(true);
    const [headerSections, setHeaderSections] = useState([]);
    const [showFailedVisit, setShowFailedVisit] = useState(false);
    const [failAppointmentReason, setFailAppointmentReason] = useState(null);
    const isInternal = UserHasAccess("Internal");
    const disableEdit = true;
    const failedAppointementState = "Failed Appointment";
    const jobTachoProductBusinessAreaId = 1;
    const requiredAction = ["ScheduledAppointment:View", "Internal"];
    const [closeButtonText, setCloseButtonText] = useState("Close Job");

    useEffect(initialLoad, [props.job]);

    function setHeaders(jobFailed, failureReason, failureAdditionalComments, amendment) {
        var headers = [
            {
                header: isInternal ? "Product Name" : "Product Details",
                value: isInternal ? props.job.productName : props.job.productDescription,
                key: 'product-type'
            },
            {
                header: isInternal && props.job.pubsJobId != null ? "Job ID / PUBS ID" : "Job ID",
                value: isInternal && props.job.pubsJobId != null ? props.job.jobId + " / " + props.job.pubsJobId : props.job.jobId,
                key: 'jod-id'
            },
            {
                header: "Job Status",
                value: props.job.state,
                key: 'job-status'
            },
            {
                header: "Business Area",
                value: props.job.jobProductBusinessArea,
                key: 'job-business-area'
            },
            {
                header: "Schedule Date",
                value: <DateToLocal format="DD/MM/YYYY HH:mm">
                    {props.job.scheduledDate}
                </DateToLocal>,
                key: 'scheduled-date'
            }
        ];

        if (props.job.completedDate !== null && props.job.completedDate !== undefined) {
            headers.push({
                header: "Completed Date",
                value: <DateToLocal format="DD/MM/YYYY HH:mm">
                    {props.job.completedDate}
                </DateToLocal>,
                key: 'completed-date'
            });
        };

        if (failureReason !== null && failureReason !== undefined && jobFailed) {
            headers.push({
                header: "Failure Reason",
                value: failureReason,
                key: 'failure-reason'
            },
            {
                header: "Failure Additional Comments",
                value: failureAdditionalComments,
                key: 'failure-additional-comments'
            });
        }

        if (amendment) {
            var amendByText = "Amendment By";
            var amendDateText = "Amendment Date";

            if (props.job.amendmentType === "Cancellation") {
                amendByText = "Cancellation By";
                amendDateText = "Cancellation Date";
            }

            headers.unshift({
                header: amendByText,
                value: props.job.amendmentBy,
                key: 'job-amendment-by'
            });
            headers.unshift({
                header: amendDateText,
                value: props.job.amendmentDate !== null ? moment(props.job.amendmentDate).format("DD/MM/YYYY") : '',
                key: 'job-amendment-date'
            });
        }

        setHeaderSections(headers);
    };

    function initialLoad() {

        if (props.job?.jobId === null || props.job?.jobId === undefined) {
            return;
        }
        const loadDataPromises = [];

        var locationPermissionsResponse = null;
        var completedVisit = null;
        var assetResponse = null;

        const getUserLocations = UserService.getLoggedInUserLocations(1, false, true)
            .then(response => locationPermissionsResponse = response);
        loadDataPromises.push(getUserLocations);

        if (props.job.state === failedAppointementState || props.job.state === "Done") {
            const getCompletedVisit = JobService.getCompletedVisit(props.job.jobId)
                .then(response => completedVisit = response);
            loadDataPromises.push(getCompletedVisit);
        }

        if (props.job.intendedAssetId) {
            const getAssets = AssetService.getAsset(props.job.intendedAssetId)
                .then(response => assetResponse = response);
            loadDataPromises.push(getAssets);
        }

        axios.all(loadDataPromises).then(function () {
            if (props.job.productName !== "Compliance Review" && completedVisit.jobVisitCompletionId !== 0) {
                setCloseButtonText("View Closed Job");
            }
            searchAsset(assetResponse !== null ? assetResponse?.registration : '');            
            setJobDetails(props.job);
            setOriginalJobDetails(props.job);
            setLocationList(locationPermissionsResponse);
            setSelectedLocations([props.job.locationId]);
            setCanSave(props.job.jobProductBusinessAreaId === jobTachoProductBusinessAreaId && isInternal && !props.job.completed && props.job.scheduledDate === null);
            setJobIsFailed(props.job.state === failedAppointementState);
            setJobCanBeFailed(props.job.jobProductBusinessAreaId === jobTachoProductBusinessAreaId && !props.job.state.toLowerCase().includes("done") && props.job.state !== "Cancelled");
            setFailAppointmentReason({
                failureReason: props.job.failureReason ?? "",
                failureAdditionalComments: props.job.failureAdditionalComments ?? ""
            });

            var amendment = props.job.amendmentType != null && (props.job.amendmentType === "Amendment" || props.job.amendmentType === "Cancellation");

            setAmendmentRequest(amendment);
            setHeaders();
            setLoading(false);
        }, () => {
            NotificationToaster.show(Intent.DANGER, "Could not get job details. Please refresh the page.");
        });
    }

    useEffect(() => {
        if (jobDetails.jobId === null) {
            return;
        }
        var minDate = moment().toDate();        
        if (jobDetails.dueDate !== null) {
            var dueDate = moment(jobDetails.dueDate).toDate();
            if (dueDate < minDate) {
                minDate = dueDate;
            }                
        }
        setMinIntendedDate(canSave ? minDate : minDateDefault);
        validateModel();
    }, [canSave, jobDetails]);

    function saveJobDetails() {
        setSaving(true);

        JobService.saveJobDetails(jobDetails)
            .then(
                () => {
                    setOriginalJobDetails(jobDetails);
                    NotificationToaster.show(Intent.SUCCESS, "job details updated successfully.");
                    setSaving(false);
                },
                () => {
                    NotificationToaster.show(Intent.DANGER, "Could not save job details. Please try again.");
                    setSaving(false);
                },
        );

        HelperFunctions.executeIfNotNull(props.onSave, jobDetails);
    }

    function onDueDateChange(item) {
        setJobDetails((previousValue) => {
            return {
                ...previousValue,
                dueDate: item ? moment(item).format("YYYY-MM-DD") : null
            }
        });
    }

    function onLocationSelect(locations) {

        var lastLocation = locations.length > 0 ? locations.pop() : null;

        setJobDetails((previousValue) => {
            return {
                ...previousValue,
                locationId: lastLocation
            }
        });

        setSelectedLocations(lastLocation == null ? [] : [lastLocation]);
    }

    function onRegistationChange(item) {
        setJobDetails((previousValue) => {
            return {
                ...previousValue,
                intendedAssetId: item.id
            }
        });
    }

    function setUseHierarchy() {
        setShowLocationsAsHierarachy(previous => !previous);
    }

    function validateModel() {
        let rules = [{ fieldName: "locationId", required: true }];

        if (isInternal) {
            rules.push({ fieldName: "dueDate", required: true, });
        }

        validate(rules, jobDetails);
    }

    function formatAssets(assetList) {
        return assetList.map(asset => {
            return {
                id: asset.assetId,
                name: asset.assetRegistration
            };
        });
    }

    function searchAsset(searchText) {
        const assetsRequest = {
            pageNumber: 1,
            searchTerm: searchText,
            filters: []
        };

        AssetService.searchAssets(assetsRequest).then(function (assetResponse) {
            setAssets(formatAssets(assetResponse.data));
        });
    }

    useEffect(function () {
        //On inital load isPostCodeValid is set to true, so we'll add the length check
        if (assetSearch.length > 0) {
            searchAsset(assetSearch);
        }
    }, [assetSearch])

    function onAssetSearchTextChange(event) {
        setAssetSearch(event);
    }

    function onFailVisit() {
        setShowFailedVisit(true);
    }

    function onFailVisitClose() {
        setShowFailedVisit(false);
    }

    function onFailVisitSave(newFailAppointmentReason) {
        if (newFailAppointmentReason != null) {
            setJobIsFailed(true);
            setFailAppointmentReason(newFailAppointmentReason);
        }
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <h2>Job Details</h2>
                        <ShowHide
                            evaluator={amendmentRequest !== null && amendmentRequest}
                            show={(
                                <NotificationInline show={true} allowClose={false} intent="warning" text={props.job.amendmentType === "Amendment" ? "Re-schedule requested" : "Cancellation requested"} />
                            )}
                        />
                        <DataDisplayTable
                            data={headerSections}
                            displayInRows={false}
                            loading={loading}
                        />
                        <UserAccess perform={["Internal"]}
                            yes={() => (
                                <Fragment>
                                    <FormDateInput
                                        id="field-asset-start-date"
                                        disabled={saving || disableEdit || !canSave}
                                        headingText="Due date:"
                                        onChange={onDueDateChange}
                                        value={jobDetails.dueDate}
                                        loading={loading}
                                        dangerHelperText={errors.dueDate}
                                        minDate={minIntendedDate}
                                    ></FormDateInput>
                                </Fragment>
                            )}
                        />
                        <FormLocationSelector
                            headingText={`Visit Location:`}
                            dangerHelperText={errors["location"]}
                            businessArea={"Tacho"}
                            loading={loading}
                            selectedLocations={selectedLocations}
                            setSelectedLocations={onLocationSelect}
                            useHierarchy={showLocationsAsHierarachy}
                            setUseHierarchy={setUseHierarchy}
                            useLocationDefaults={false}
                            useCustomLocations
                            customLocations={locationList}
                            locationDefaultsToTrue
                            singleSelection
                            disabled={saving || disableEdit || !canSave}
                        />
                        <ShowHide
                            evaluator={jobDetails.jobProductBusinessAreaId === 2}
                            show=
                            {
                                <FormSuggest
                                    onQueryChange={onAssetSearchTextChange}
                                    items={assets}
                                    selectedValue={jobDetails.intendedAssetId}
                                    loading={loading}
                                    onItemSelect={onRegistationChange}
                                    headingText="Registration"
                                    dangerHelperText={errors.intendedAssetId}
                                    disabled={saving || disableEdit || !canSave}
                                    clearSelection={false}
                                />
                            }
                        >
                        </ShowHide>

                        <AlertUnsavedData
                            isDirty={isDataDirty}>
                        </AlertUnsavedData>
                        <ShowHide
                            evaluator={canSave && !disableEdit}
                            show=
                            {
                                <Fragment>
                                    <div className="inline-item">
                                    <ButtonSave
                                        onClick={saveJobDetails}
                                        disabled={saving}
                                        simpleDisabled={!isValid}
                                        loading={loading}
                                        ></ButtonSave>
                                    </div>
                                </Fragment>
                            }
                        >
                        </ShowHide>
                        <ShowHide
                            evaluator={jobCanBeFailed}
                            show=
                            {
                                <div className="inline-item">
                                    <Button onClick={onFailVisit}
                                        id="button-edit-failed-visit"
                                        text={(jobIsFailed ? "Edit Failed Visit" : "Fail Visit")}
                                        intent={(jobIsFailed ? Intent.PRIMARY : Intent.DANGER)}
                                    ></Button>
                                </div>
                            }
                        >
                        </ShowHide>

                        <ShowHide
                            evaluator={closeButtonText === "View Closed Job" || (!jobDetails.completed && jobDetails.jobProductBusinessArea === "Tacho" && jobDetails.productName !== 'Compliance Review')}
                            show=
                            {
                                <div className="inline-item">
                                    <LinkButton text={closeButtonText} intent="primary" href={`/job/${jobDetails.jobId}/close-job`} />
                                </div>
                            }
                        >
                        </ShowHide>
                    </div>
                    <FailVisit
                        jobFailed={jobIsFailed}
                        failAppointmentReason={failAppointmentReason}
                        jobId={jobDetails.jobId}
                        showFailedAppointment={showFailedVisit}
                        loading={loading}
                        onFailVisitClose={onFailVisitClose}
                        onFailVisitSave={onFailVisitSave}
                    />
                </Fragment>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}