import ApiService from 'services/ApiService'

const badRequestCode = 400;

export const RiskService = {
    getSettings() {
		return new Promise((resolve, reject) => {
			ApiService.get('/risk/settings').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
    },
    updateSettings(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put('/risk/settings', settings).then( 
                (response) => {
			        resolve(response);
				},
                (error) => {
                    if (error.response.status === badRequestCode) {
						reject(error.response.data);
					} else {
						reject("Unable to update settings, please try again.");
					}
                }
            );
		});
	},
	getCategories(areaId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/risk/Options/Area/${areaId}/Categories`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	getEvents(areaId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/risk/Settings/Events/Area/${areaId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	saveEvents(areaId, events) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/risk/Settings/Events/Area/${areaId}`, events).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to update events. Please try again later.");
					}
				});
		});
	},

	getBands() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/risk/Settings/Bands`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	getGraphBands() {
		return new Promise((resolve) => {
			this.getBands().then(function (bandsResponse) {

				var minScore = 0;
				const maxScore = 99999;

				resolve(bandsResponse.map(function (band) {
					var levelMax = band.maxScore == null ? maxScore : band.maxScore;

					var levelBand = [
						{
							yAxis: minScore,
							name: band.name,
							itemStyle: { color: `#${band.colour}` }
						},
						{
							yAxis: levelMax,
							itemStyle: { color: `#${band.colour}` }
						}
					];

					minScore = levelMax;
					return levelBand;
				}));

			}, function () {
				resolve([]);
			})
		});
	},

	saveBands(bands) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/risk/Settings/Bands`, bands).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to update bands. Please try again later.");
					}
				});
		});
	},

	getChangeLog(pageSize, pageNumber, startDate, endDate) {
        return new Promise((resolve, reject) => {
			ApiService.get(`/risk/ChangeLog?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	deleteRiskData() {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/risk/Settings`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	getRiskStatus() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/risk/Status`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	getPeriodInfo(locations, periodId = null) {

		var periodInfoUrl = periodId == null ? '/risk/Scores' : `/risk/Scores/Period/${periodId}`;

		var filters = locations.map((location) => {
			return {"Key": "LOCATION", "Value": location};
		});

		return new Promise((resolve, reject) => {
			ApiService.post(periodInfoUrl, { "filters": filters }).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	getTrends(locations) {

		var filters = locations.map((location) => {
			return { "Key": "LOCATION", "Value": location };
		});

		return new Promise((resolve, reject) => {
			ApiService.post("/risk/Scores/Trends", { "filters": filters }).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	getLocationScores(requestCount, pageNumber, searchTerm, softField, sortDirection, locations, periodNumber) {

		var filters = locations.map((location) => {
			return { "Key": "LOCATION", "Value": location };
		});

		var searchRequest = {
			"requestCount": requestCount,
			"pageNumber": pageNumber,
			"searchTerm": searchTerm,
			"sortField": softField,
			"sortDirection": sortDirection,
			"filters": filters
		}

		return new Promise((resolve, reject) => {
			ApiService.post(`/risk/Scores/Period/${periodNumber}/Locations`, searchRequest).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});

	},

	getEmployeeProfile(employeeId) {

		return new Promise((resolve, reject) => {
			ApiService.get(`/risk/Employees/${employeeId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},

	getEmployeePeriodBreakdown(employeeId, periodNumber) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/risk/Employees/${employeeId}/period/${periodNumber}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	},

	getEmployeeListing(requestCount, pageNumber, searchTerm, softField, sortDirection, locations, periodNumber) {
		var filters = locations.map((location) => {
			return { "Key": "LOCATION", "Value": location };
		});

		var searchRequest = {
			"requestCount": requestCount,
			"pageNumber": pageNumber,
			"searchTerm": searchTerm,
			"sortField": softField,
			"sortDirection": sortDirection,
			"filters": filters
		}

		return new Promise((resolve, reject) => {
			ApiService.post(`/risk/Employees/Period/${periodNumber}`, searchRequest).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	suppressEvent(eventId, request) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/risk/Events/${eventId}/Suppress`, request).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	unsuppressEvent(eventId, request) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/risk/Events/${eventId}/Unsuppress`, request).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	}
}