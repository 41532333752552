import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import { FormDateRangeInput, FormLocationSelector, FormTextInput} from 'components/form-fields';
import { Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { JobService, LocationService } from 'services';
import { ShowHide } from "components/layout";
import classNames from "classnames";
import moment from "moment";
import { Tooltip } from 'components/tooltip';
import { Filter } from 'components/filtering';
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { DateToLocal } from "components/dates";
import { NotificationToaster } from "components/notifications";
import { Intent } from '@blueprintjs/core';
import { StringToEllipsesString } from 'components/formatting';
import { Link } from 'react-router-dom';
import './JobListing.css';
export function JobCompletedListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;
    const productDescLength = 20;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loadingData, setLoadingData] = useState(true);
    const [locationSetUp, setLocationSetUp] = useState(false);
    const [dateSetUp, setDateSetUp] = useState(false);
    const [tableSortBy, setTableSortBy] = useState({ headerName: "Date", sortName: "COMPLETEDSCHEDULEDDATE" });
    const [tableSortDir, setTableSortDir] = useState("D");
    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const searchInputRef = useRef(null);
    const canAccessPage = UserHasAccess(props.requiredAction);
    const noDataMessage = "No Jobs found.";
    const tableHeaders = ["Completed Date", "Product Name", "Location", "Status"];
    const sortableHeaders = [{ headerName: "Product Name", sortName: "PRODUCTNAME" },
        { headerName: "Location", sortName: "LOCATIONNAME" },
        { headerName: "Completed Date", sortName: "COMPLETEDSCHEDULEDDATE" },
        { headerName: "Status", sortName: "STATE" }];
    const doneState = "Done";
    const failedAppointmentState = "Failed Appointment";
    const dateFilterReduxName = "jobListingDates";
    const jobLocationReduxName = "jobListingLocation";
    const jobSearchReduxName = "jobSearch";

    const reduxDates = useSelector(state => state.filters[dateFilterReduxName]);
    const reduxLocations = useSelector(state => state.filters[jobLocationReduxName]);
    const reduxSearch = useSelector(state => state.filters[jobSearchReduxName]);
    const dispatch = useDispatch();

    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationListOnFilterOpen = useRef(selectedLocations);
    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentFilters = useRef();
    const currentStartDate = useRef();
    const currentEndDate = useRef();

    useEffect(() => {
        setUpPage();
    }, []);


    useEffect(() => {
        //This will trigger if the data changes, but will only process if the loadingData is set to true.
        if (loadingData && canAccessPage && pageFilters.length > 0 &&
            ((startDate && endDate) || (!startDate && !endDate)) &&
            locationSetUp && dateSetUp && selectedLocations != null) {

            let filtersArray = getFilters();

            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === searchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentFilters.current === filtersArray.length &&
                currentStartDate.current === startDate &&
                currentEndDate.current === endDate
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = searchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentFilters.current = filtersArray.length;
            currentStartDate.current = startDate;
            currentEndDate.current = endDate;

            const jobsRequest = {
                requestCount: pageSize,
                pageNumber: pageNumber,
                sortField: tableSortBy.sortName,
                sortDirection: tableSortDir,
                filters: filtersArray
            };

            JobService.getCompletedJobs(jobsRequest).then(r => {
                let data = [];
                
                if (r?.data != null) {
                    data = r.data.map((d) => {
                        let prodName = <StringToEllipsesString length={productDescLength} showTooltip={true}>{d.productName}</StringToEllipsesString>;
                        let productNameWithLink = d.legacyJob ? <a href={`${window.env.VISION_URL}/#/WorkItemReport/Job/${d.pubsJobId}`}>{prodName}</a>
                            : <Link to={`/completed-visits/${d.jobId}`} key={`job-link-${d.jobId}`}>{prodName}</Link>;
                        return [<DateToLocal key={`completed-date-${d.jobId}`} format='DD/MM/YYYY'>{d.completedDate}</DateToLocal>,
                            productNameWithLink, d.locationName, d.state];
                    });
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoadingData(false);
                focusOnTextBox(searchInputRef);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Visits could not be loaded. Please try loading the page again.");
                setLoadingData(false);
            });
        }

    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, appliedFilters, selectedLocations]);

    useEffect(() => {
        if (debouncedSearchTerm !== null && debouncedSearchTerm !== currentSearchTerm.current) {
            setPageNumber(1);
            setLoadingData(true);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (!loadingData) {
            dispatch(SetFiltering(jobLocationReduxName,
                {
                    list: selectedLocations
                }
            ));
        }
    }, [selectedLocations]);

    useEffect(() => {
        //We know the redux store has loaded now
        setDates();
    }, [reduxDates]);

    useEffect(() => {
        //We only want to set the locations once, else it gets stuck in a loop
        if (!locationSetUp) {
            setupLocationInfo();
        }
    }, [reduxLocations]);

    useEffect(() => {
        //We know the redux store has loaded now
        if (reduxSearch != null && reduxSearch.length > 0 && reduxSearch !== searchTerm) {
            setSearchTerm(reduxSearch);
        }
    }, [reduxSearch]);

    function focusOnTextBox(InputRef) {
        if (InputRef.current != null) {
            setTimeout(function () {
                InputRef.current.focus();
            });
        }
    }

    function setUpPage() {
        let itemStates = [
            { "id": doneState, "name": doneState },
            { "id": failedAppointmentState, "name": failedAppointmentState },
        ];
        setShowFilters(false);

        var filterArray = [
            {
                "displayName": "Status", "name": "State", "items": [
                    ...itemStates
                ]
            }
        ];

        setPageFilters(filterArray);
    }

    function getFilters(){
        let mappedFilters = appliedFilters.map(function (f) {
            return {
                key: f.name,
                ...f
            }
        });

        let filtersArray = [...mappedFilters,
        { "key": "Search", "value": searchTerm }
        ];

        if (startDate && endDate) {
            filtersArray = [...filtersArray,
            { "key": "CompletedScheduledStartDate", "value": startDate },
            { "key": "CompletedScheduledEndDate", "value": endDate }
            ];
        }

        if (selectedLocations != null) {
            selectedLocations.forEach(function (l) {
                filtersArray = [...filtersArray,
                { "key": "Location", "value": l }
                ];
            });
        }

        return filtersArray;
    }

    function setDates() {
        if (reduxDates && reduxDates.startDate !== null && reduxDates.endDate !== null) {
            setStartDate(reduxDates.startDate?.format("YYYY-MM-DD"));
            setEndDate(reduxDates.endDate?.format("YYYY-MM-DD"));
        } else if (dateSetUp) {
            setStartDate(null);
            setEndDate(null);
        }
        else {
            let defaultmonths = 3;
            setStartDate(moment().add(defaultmonths * -1, 'M').format("YYYY-MM-DD"));
            setEndDate(moment().format("YYYY-MM-DD"));
        }
        setDateSetUp(true);
        setLoadingData(true);
    }
    function setupLocationInfo() {
        LocationService.getFormLocationSelectorCustomLocations(['Tacho']).then(function (locations) {
            setLocationList(locations);
            setLocationSetUp(true);
            if (reduxLocations != null && reduxLocations.list.length > 0) {
                setSelectedLocations(reduxLocations.list);
            } else {
                setSelectedLocations(locations.location[0].items.map(l => l.id));
            }
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, `Locations failed ${error}`);
        });
        setLoadingData(true);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setLoadingData(true);
        setPageNumber(1);
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onDateRangeChange(item) {
        if ((item?.startDate?.date != null && item?.endDate?.date != null) || (item?.startDate?.date == null && item?.endDate?.date == null)) {
            setPageNumber(1);

            //Using the redux store should mean the setDates is called
            dispatch(SetFiltering(dateFilterReduxName, {
                startDate: item.startDate?.date,
                endDate: item.endDate?.date
            }));
        }
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            setLoadingData(true);
        }
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
        dispatch(SetFiltering(jobSearchReduxName, item.target.value));

    }
    return (
        <UserAccess perform={props.requiredAction}
            yes={() => (
                <div className="row">
                    <div className="inline-items spacer-bottom">
                        <h1>{props.title}</h1>
                    </div>

                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && pageFilters.length === 0}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormTextInput
                                            maxLength={50}
                                            inputRef={searchInputRef}
                                            placeholder="Product or Reference"
                                            onChange={onSearchChange}
                                            value={searchTerm}
                                            large
                                            disabled={loadingData}
                                            icon="search"
                                            id="job-search-field" />

                                        <FormLocationSelector
                                            businessArea={""}
                                            loading={loadingData}
                                            selectedLocations={selectedLocations}
                                            setSelectedLocations={setSelectedLocations}
                                            useHierarchy={false}
                                            useLocationDefaults={false}
                                            locationDefaultsToTrue={true}
                                            onLocationListClose={onLocationListClose}
                                            onLocationListOpened={onLocationListOpened}
                                            customLocations={locationList}
                                            useCustomLocations={true}
                                        />
                                        <FormDateRangeInput
                                            onChange={onDateRangeChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            disabled={loadingData}
                                            headingText={""}
                                            large
                                        ></FormDateRangeInput>

                                        <Fragment>
                                            <Tooltip content="Filter" position="right">
                                                <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                            </Tooltip>
                                        </Fragment>
                                    </div>
                                </div>
                                <Filter filterName="completedJobs" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />
                            </Fragment>
                        )}
                    />

                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}